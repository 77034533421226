import React, { useContext, useEffect, useState } from 'react';
import { OverlayContext } from '../../../contexts/overlay';
import { Drawer } from './drawer';
import { Menu } from './menu';
import { GameDocumentContext } from '../../../contexts/game-document';
import { Color } from '../../../types/theme';
import { ChatContext } from '../../../contexts/chat';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { HubResponse } from '../../../types/responses/hub-response';
import { GetTeamChatHub, getTeamSelectedAsync } from '../../../services/teams';
import { PlayerContext } from '../../../contexts/player';
import {
  GetAssessmentStateHub,
  GetGlobalChatHub
} from '../../../services/games';
import { GameContext } from '../../../contexts/game';
import { ChatState } from '../../../types/state/websocket/chat-state';
import { GetGroupChatHub } from '../../../services/groups';
import { GetTeamSelectedResponse } from '../../../types/responses/team-response';
import { addUnreadChat } from '../../../utils/game-engine/chat';
import {
  NotificationContent,
  NotificationContext
} from '../../../contexts/notification';
import { PlayerAssessmentContext } from '../../../contexts/player-assessment';
import { hexToRGB } from '../../../utils/hex-to-rgb';
import { sendPushNotification } from '../../../utils/game-engine/send-push-notification';

export const Overlay = () => {
  const [playerState, setPlayerState] = useContext(PlayerContext);
  const [gameState] = useContext(GameContext);
  const [gameDocument] = useContext(GameDocumentContext);
  const [overlay] = useContext(OverlayContext);
  const [chatState, setChatState] = useContext(ChatContext);
  const theme: Color[] = gameDocument?.theme?.colors ?? [];

  const [connectionTeamRef, setConnectionTeam] = useState<HubConnection>();
  const [teamHubResponse, setTeamHubResponse] = useState<HubResponse>();
  const [connectionGamesRef, setConnectionGames] = useState<HubConnection>();
  const [gamesHubResponse, setGamesHubResponse] = useState<HubResponse>();
  const [connectionGroupsRef, setConnectionGroups] = useState<HubConnection>();
  const [groupHubResponse, setGroupHubResponse] = useState<HubResponse>();
  const [playerChatHubResponse, setPlayerChatHubResponse] =
    useState<HubResponse>();
  const [connectionPlayerChatRef, setConnectionPlayerChat] =
    useState<HubConnection>();
  const [connectionAssessmentRef, setConnectionAssessment] =
    useState<HubConnection>();
  const [assessmentHubResponse, setAssessmentHubResponse] =
    useState<HubResponse>();
  const [newChat, setNewChat] = useState<ChatState>();
  const [isNewChat, setIsNewChat] = useState<boolean>(false);
  const [selectedTeamState, setSelectedTeamState] =
    useState<GetTeamSelectedResponse>();
  const [notification, setNotification] = useContext(NotificationContext);
  const [playerAssessment, setPlayerAssessment] = useContext(
    PlayerAssessmentContext
  );

  function createTeamHubConnection() {
    GetTeamChatHub(playerState.playerState?.teamCode!).then((response) => {
      if (response) {
        setTeamHubResponse(response);
        const con = new HubConnectionBuilder()
          .withUrl(`${response.endpointUrl}`, {
            accessTokenFactory: () => response.accessToken!
          })
          .withAutomaticReconnect()
          .build();
        setConnectionTeam(con);
      }
    });
  }

  function createGamesHubConnection() {
    GetGlobalChatHub(gameState?.gameCode!).then((response) => {
      if (response) {
        setGamesHubResponse(response);
        const con = new HubConnectionBuilder()
          .withUrl(`${response.endpointUrl}`, {
            accessTokenFactory: () => response.accessToken!
          })
          .withAutomaticReconnect()
          .build();
        setConnectionGames(con);
      }
    });
  }

  function createAssessmentHubConnection() {
    GetAssessmentStateHub(gameState?.gameState?.code!).then((response) => {
      if (response) {
        setAssessmentHubResponse(response);
        const con = new HubConnectionBuilder()
          .withUrl(`${response.endpointUrl}`, {
            accessTokenFactory: () => response.accessToken!
          })
          .withAutomaticReconnect()
          .build();
        setConnectionAssessment(con);
      }
    });
  }

  function createPlayerChatHubConnection() {
    GetGlobalChatHub(gameState?.gameCode!).then((response) => {
      if (response) {
        setPlayerChatHubResponse(response);
        const con = new HubConnectionBuilder()
          .withUrl(`${response.endpointUrl}`, {
            accessTokenFactory: () => response.accessToken!
          })
          .withAutomaticReconnect()
          .build();
        setConnectionPlayerChat(con);
      }
    });
  }

  async function createGroupHubConnection() {
    const teamState = (await getTeamSelectedAsync(
      gameState.gameCode!,
      playerState.playerState?.teamCode!
    )) as GetTeamSelectedResponse;

    setSelectedTeamState(teamState);
    GetGroupChatHub(teamState.groupCode!).then((response) => {
      if (response) {
        setGroupHubResponse(response);
        const con = new HubConnectionBuilder()
          .withUrl(`${response.endpointUrl}`, {
            accessTokenFactory: () => response.accessToken!
          })
          .withAutomaticReconnect()
          .build();

        setConnectionGroups(con);
      }
    });
  }

  const showNewMessage = (playerName: string, message: string) => {
    const addScoreNotification: NotificationContent = {
      icon: 'chat',
      isHide: false,
      message: (
        <div className="d-flex flex-column">
          <strong>{playerName}</strong>
          <small>{message}</small>
        </div>
      ),
      color: 'k-button--gradient'
    };
    const content: NotificationContent[] = notification.content;
    content.push(addScoreNotification);
    setNotification({ ...notification, content });
  };

  const getFooterImage = () => ({
    background: `url(${gameDocument?.theme?.footerUrl ?? ''} )`,
    backgroundSize: `cover`,
    backgroundPosition: `center`
  });

  useEffect(() => {
    setChatState((prev) => ({
      ...prev,
      isNewChat: false
    }));
    createTeamHubConnection();
    createGamesHubConnection();
    createGroupHubConnection();
    createPlayerChatHubConnection();
    createAssessmentHubConnection();
  }, []);

  useEffect(() => {
    createTeamHubConnection();
    createGroupHubConnection();
  }, [playerState?.playerState?.teamCode]);

  const sendNotification = ({
    senderCode,
    message,
    avatar,
    playerName
  }: {
    senderCode: string;
    message: string;
    avatar: string;
    playerName: string;
  }) => {
    const code = playerState.playerState?.code;
    const gameCode = gameDocument?.gameCode;
    if (code && gameCode && code !== senderCode) {
      sendPushNotification({
        message,
        avatar: avatar || '/favicon.ico',
        playerName,
        url: `${window.location.origin}/games/${gameCode}`
      });
    }
  };

  useEffect(() => {
    if (connectionTeamRef) {
      try {
        connectionTeamRef
          .start()
          .then(() => {
            connectionTeamRef.on(
              teamHubResponse?.method!,
              (playerCode, playerName, message, avatarUrl, date) => {
                setNewChat({
                  author: {
                    id: playerCode!,
                    name: playerName!,
                    avatarUrl: avatarUrl!
                  },
                  timestamp: new Date(date),
                  group: playerState?.playerState?.teamCode,
                  text: message
                });

                sendNotification({
                  senderCode: playerCode,
                  message,
                  avatar: avatarUrl || '/favicon.ico',
                  playerName
                });
                addUnreadChat(
                  gameDocument?.gameCode!,
                  teamHubResponse?.method!
                );
              }
            );
          })
          .catch((err: any) => {});
      } catch (error) {}
    }

    return () => {
      connectionTeamRef?.stop();
    };
  }, [connectionTeamRef]);

  useEffect(() => {
    if (connectionPlayerChatRef) {
      try {
        connectionPlayerChatRef
          .start()
          .then(() => {
            connectionPlayerChatRef.on(
              `PlayerChat_${playerState?.playerState?.code}`,
              (playerCode, playerName, message, avatarUrl, date) => {
                setNewChat({
                  author: {
                    id: playerCode!,
                    name: playerName!,
                    avatarUrl: avatarUrl!
                  },
                  timestamp: new Date(date),
                  group: (playerName as string).includes('(Facilitator)')
                    ? 'Facilitators'
                    : playerCode,
                  text: message
                });

                sendNotification({
                  senderCode: playerCode,
                  message,
                  avatar: avatarUrl || '/favicon.ico',
                  playerName
                });
                if ((playerName as string).includes('(Facilitator)')) {
                  addUnreadChat(
                    gameDocument?.gameCode!,
                    `PlayerChat_${playerState?.playerState?.code}_Facilitator`
                  );
                } else {
                  addUnreadChat(
                    gameDocument?.gameCode!,
                    `PlayerChat_${playerState?.playerState?.code}_${playerCode}`
                  );
                }
              }
            );
          })
          .catch((err: any) => {});
      } catch (error) {}
    }

    return () => {
      connectionPlayerChatRef?.stop();
    };
  }, [connectionPlayerChatRef]);

  useEffect(() => {
    if (connectionGamesRef) {
      try {
        connectionGamesRef
          .start()
          .then(() => {
            connectionGamesRef.on(
              gamesHubResponse?.method!,
              (playerCode, playerName, message, avatarUrl, date) => {
                setNewChat({
                  author: {
                    id: playerCode!,
                    name: playerName!,
                    avatarUrl: avatarUrl!
                  },
                  timestamp: new Date(date),
                  group: gameState?.gameCode,
                  text: message
                });

                sendNotification({
                  senderCode: playerCode,
                  message,
                  avatar: avatarUrl || '/favicon.ico',
                  playerName
                });
                addUnreadChat(
                  gameDocument?.gameCode!,
                  gamesHubResponse?.method!
                );
              }
            );
          })
          .catch((err: any) => {});
      } catch (error) {}
    }

    return () => {
      connectionGamesRef?.stop();
    };
  }, [connectionGamesRef]);

  useEffect(() => {
    if (connectionGroupsRef) {
      try {
        connectionGroupsRef
          .start()
          .then(() => {
            connectionGroupsRef.on(
              groupHubResponse?.method!,
              (playerCode, playerName, message, avatarUrl, date) => {
                setNewChat({
                  author: {
                    id: playerCode!,
                    name: playerName!,
                    avatarUrl: avatarUrl!
                  },
                  timestamp: new Date(date),
                  group: selectedTeamState?.groupCode,
                  text: message
                });

                sendNotification({
                  senderCode: playerCode,
                  message,
                  avatar: avatarUrl || '/favicon.ico',
                  playerName
                });
                addUnreadChat(
                  gameDocument?.gameCode!,
                  groupHubResponse?.method!
                );
              }
            );
          })
          .catch((err: any) => {});
      } catch (error) {}
    }

    return () => {
      connectionGroupsRef?.stop();
    };
  }, [connectionGroupsRef]);

  useEffect(() => {
    if (connectionAssessmentRef) {
      try {
        connectionAssessmentRef
          .start()
          .then(() => {
            connectionAssessmentRef.on(
              assessmentHubResponse?.method!,
              (data) => {
                setPlayerAssessment((prevState) => {
                  return JSON.parse(data);
                });
              }
            );
          })
          .catch((err: any) => {});
      } catch (error) {}
    }

    return () => {
      connectionAssessmentRef?.stop();
    };
  }, [connectionAssessmentRef]);

  useEffect(() => {
    if (newChat) {
      setChatState(newChat);

      if (newChat.author.id !== playerState?.playerState?.code) {
        showNewMessage(newChat?.author?.name!, newChat.text ?? '');
      }

      if (chatState && overlay.activeTab !== 'chat') {
        setIsNewChat(true);
      } else {
        setIsNewChat(false);
      }
    }
  }, [newChat]);

  useEffect(() => {
    if (theme) {
      const backgroundColor = theme.find((x) => x.type === 'Background & Text');

      if (backgroundColor) {
        const { color } = backgroundColor;
        const menu = document.getElementsByClassName('overlay__menu');
        const drawer = document.getElementsByClassName('overlay__drawer');

        const background = color
          ? hexToRGB(color).replace(
              '1)',
              overlay.drawerIsOpen ? '0.85)' : '0.2)'
            )
          : `rgba(0,58,100, ${overlay.drawerIsOpen ? '0.85' : '0.2'})`;

        if (menu.length > 0) {
          (menu[0] as HTMLElement).style.background = background;
        }

        if (drawer.length > 0) {
          (drawer[0] as HTMLElement).style.background = background;
        }
      }
    }
  }, [theme, overlay.drawerIsOpen]);

  useEffect(() => {
    const infoColor = theme?.find((x) => x.type === 'Info')?.color;
    const overlayTimer = document.getElementsByClassName('overlay__timer');

    const background = `linear-gradient(90deg, ${
      infoColor
        ? hexToRGB(infoColor).replace('1)', '0.85)')
        : 'rgba(13,197,232,0.85)'
    } 0%, ${
      infoColor
        ? hexToRGB(infoColor).replace('1)', '0.85)')
        : 'rgba(13,197,232,0.85)'
    } ${overlay.progressBar}%, rgba(0,0,0,0) ${
      overlay.progressBar
    }%, rgba(0,0,0,0) 100%)`;

    for (let i = 0; i < overlayTimer.length; i++) {
      (overlayTimer[i] as HTMLElement).style.background = background;
    }
  }, [theme, overlay.progressBar]);

  return (
    <div
      className={`game__overlay game__overlay--${
        overlay.drawerIsOpen ? 'active' : 'in-active'
      }`}>
      <div className={'overlay__menu'}>
        <div className={'overlay__timer'} />
        <Menu
          isNewChat={isNewChat}
          handleOpenChat={() => setIsNewChat(false)}
        />
      </div>
      <div className={'overlay__drawer'}>
        <div className={'overlay__timer'} />
        <Drawer handleOpenChat={() => setIsNewChat(false)} />
      </div>
      {overlay.drawerIsOpen && (
        <div className={'overlay__footer'} style={getFooterImage()} />
      )}
    </div>
  );
};
