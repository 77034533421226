import React, { PropsWithChildren, useState } from 'react';

type OverlayTimerStatus = 'start' | 'stop';

export interface OverlayState {
  drawerIsOpen: boolean;
  activeTab: string;
  progress: number;
  progressBar: number;
  interval: number;
  progressIsOpen: boolean;
  timerAssId?: string;
  gameName: string;
  gameLogo: string;
  progressChild: string;
  timerStatus: OverlayTimerStatus;
}

const DefaultOverlayState: OverlayState = {
  drawerIsOpen: false,
  activeTab: 'info',
  progress: 0,
  progressBar: 0,
  interval: 0,
  progressIsOpen: false,
  timerAssId: '',
  gameName: '',
  gameLogo: '',
  progressChild: '',
  timerStatus: 'stop'
};

const OverlayContext = React.createContext<
  [
    initialState: OverlayState,
    setState: React.Dispatch<React.SetStateAction<OverlayState>>
  ]
>([DefaultOverlayState, () => {}]);

const OverlayProvider = (props: PropsWithChildren) => {
  const [state, setState] = useState<OverlayState>(DefaultOverlayState);
  return (
    <OverlayContext.Provider value={[state, setState]}>
      {props.children}
    </OverlayContext.Provider>
  );
};

export { OverlayContext, OverlayProvider };
