import React, { useEffect, useState, useContext } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate } from 'react-router-dom';

import UserIcon from '../header/user-icon';
import UserCard from '../header/user-card';
import { GameContext } from '../../contexts/game';
import { GameDocumentContext } from '../../contexts/game-document';
import { ResourceGetById } from '../../utils/game-document/resources';

export interface BreadcrumbLocation {
  href: string;
  label: string;
}

interface PageBaseProps {
  breadcrumb?: BreadcrumbLocation[] | string;
  children?: React.ReactNode;
  error?: Error;
  className?: string;
}

export const FacilitatorPage = ({
  children,
  breadcrumb: pageLocation,
  className
}: PageBaseProps) => {
  const [game] = useContext(GameContext);
  const [locations, setLocation] = useState<BreadcrumbLocation[]>([]);
  const [gameDocument] = useContext(GameDocumentContext);
  const [logoUrl, setLogoUrl] = useState<string>('');
  const navigate = useNavigate();

  const initLocations = () => {
    if (pageLocation) {
      if (typeof pageLocation === 'string') {
        let splitted = pageLocation.split('/');
        const locs = splitted.map<BreadcrumbLocation>((path) => ({
          href: `/${path}`,
          label: path
        }));
        setLocation(locs);
        return;
      }
      setLocation(pageLocation);
    }
  };

  useEffect(() => {
    initLocations();
  }, []);

  useEffect(() => {
    initLocations();
  }, [pageLocation]);

  const navigateTo = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    const logoResId = gameDocument?.gameDocument?.overview?.logoResId ?? '';

    if (logoResId !== '') {
      const logo: string = ResourceGetById(
        gameDocument?.gameDocument?.resources!,
        gameDocument?.gameDocument?.overview?.logoResId
      )?.value!;

      setLogoUrl(logo);
    }
  }, [gameDocument]);

  return (
    <div className={`page-base min-vh-100 ${className ?? ''}`}>
      <div className={'page-base__header'}>
        <div className={'d-flex header__title w-full'}>
          {logoUrl && logoUrl !== '' && (
            <div className={'d-flex facilitator-logo'}>
              <img src={logoUrl} alt={'Game logo'}></img>
            </div>
          )}

          <div className={'d-flex justify-content-between w-full'}>
            <div className={'d-flex flex-column gap-1'}>
              <h1 className={'text-primary'}>
                <b>{game.activityState?.eventName}</b>
              </h1>
              <span>Game Control</span>
            </div>

            <div className="d-flex gap-2 align-items-center">
              <UserCard />
              <UserIcon />
            </div>
          </div>
          <Breadcrumb>
            {locations?.map((loc, index) => (
              <Breadcrumb.Item
                key={index}
                onClick={() => {
                  navigateTo(loc.href);
                }}>
                {loc.label}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>
      </div>
      <main>{children}</main>
    </div>
  );
};
