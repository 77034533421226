import {
  Chat,
  ChatMessageSendEvent
} from '@progress/kendo-react-conversational-ui';
import { useEffect, useState } from 'react';
import { TeamResponse } from '../../types/responses/team-response';
import { ChatState } from '../../types/state/websocket/chat-state';
import { useAuth } from 'react-oidc-context';

export interface FasilitatorChatProps {
  item?: TeamResponse;
  messages?: ChatState[];
  onSendMessage?: (message: string) => void;
  className?: string;
  code?: string;
}

export const FacilitatorChat = ({
  onSendMessage = () => {},
  ...props
}: FasilitatorChatProps) => {
  const [messages, setMessages] = useState<ChatState[]>([]);
  const auth = useAuth();
  const userId = parseInt(auth?.user?.profile.sub!);
  const user = {
    id: userId?.toString(),
    avatarUrl:
      'https://demos.telerik.com/kendo-ui/content/web/Customers/RICSU.jpg'
  };
  const addNewMessage = (event: ChatMessageSendEvent) => {
    if (event?.message?.text) {
      onSendMessage(event?.message?.text);
    }
  };

  useEffect(() => {
    if (props.messages) {
      setMessages(props.messages);
    }
  }, [props?.messages]);

  return (
    <div className={'d-flex flex-column gap-3 h-100'}>
      <h3 className={'text-dark'}>
        <b>{props?.item?.name}</b>
      </h3>
      <Chat
        user={user}
        messages={messages}
        onMessageSend={addNewMessage}
        width={'100%'}
        placeholder={'Type a message...'}
        className={`flex-grow-1 ${props.className}`}
      />
    </div>
  );
};
