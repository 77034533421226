import React from 'react';
import { fromLonLat } from 'ol/proj';
import { LineString } from 'ol/geom';
import { Feature, Overlay } from 'ol';
import { getDistance } from 'ol/sphere';
import cloneDeep from 'lodash.clonedeep';
import { Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import { Coordinate } from 'ol/coordinate';
import VectorSource from 'ol/source/Vector';

import {
  createCustomTaskPosition,
  createPlayerItem,
  createTaskPlayerContainer
} from '../utils/game-engine/task-position';
import { GameContext } from '../contexts/game';
import { PlayerContext } from '../contexts/player';
import { MapContext } from '../components/map/map-context';
import { GameDocumentContext } from '../contexts/game-document';
import { MapIllustrationContext } from '../components/map/map-illustration-context';

export const useGameTaskPosition = ({ mapType }: { mapType: string }) => {
  const map = React.useContext(MapContext);
  const mapIllustration = React.useContext(MapIllustrationContext);
  const [game] = React.useContext(GameContext);
  const [gameDocument] = React.useContext(GameDocumentContext);
  const [player] = React.useContext(PlayerContext);
  const [hasTaskOverlay, setHasTaskOverlay] = React.useState(false);

  const lineColor =
    gameDocument?.theme?.colors?.find((x) => x.type === 'Info')?.color ||
    "#0DC5E8'";

  const mapContext = React.useMemo(
    () => (mapType === 'illustration' ? mapIllustration : map),
    [mapType, map, mapIllustration]
  );

  //#region Add task element and its overlay
  const addTaskOverlay = React.useCallback(
    (
      id: string,
      name: string,
      iconUrl: string,
      position: Coordinate | [number, number]
    ) => {
      if (mapContext) {
        const taskExist = mapContext.getOverlayById(id);
        // prevent create task overlay more than once
        if (taskExist) return;

        const { showTasknameVisibility } =
          gameDocument.gameDocument?.settings.inGame!;
        const taskElement = createCustomTaskPosition({
          id,
          name,
          iconUrl,
          showTasknameVisibility
        });

        const overlay = new Overlay({
          id,
          position: fromLonLat(position || [0, 0]),
          positioning: 'center-center',
          element: taskElement
        });

        mapContext.addOverlay(overlay);
        setHasTaskOverlay(true);
      }
    },
    [mapContext, gameDocument.gameDocument?.settings.inGame]
  );

  const removeTaskOverlay = React.useCallback(
    (id: string) => {
      if (mapContext) {
        const getTask = (
          mapType === 'illustration' ? mapIllustration : map
        ).getOverlayById(id);
        // if task not found, return
        if (!getTask) return;

        (mapType === 'illustration' ? mapIllustration : map).removeOverlay(
          getTask
        );
      }
    },
    [mapContext]
  );

  const updateTaskOverlay = React.useCallback(
    ({
      id,
      icon,
      name,
      distance
    }: {
      id: string;
      icon?: string;
      name?: string;
      distance?: number;
    }) => {
      const taskIcon = document.getElementById(
        `icon-${id}`
      ) as HTMLImageElement;
      if (taskIcon && icon) {
        taskIcon.src = icon;
      }

      const taskName = document.getElementById(
        `taskName-${id}`
      ) as HTMLSpanElement;
      if (taskName && name) {
        taskName.innerHTML = name;
      }

      const taskDistance = document.getElementById(
        `taskDistance-${id}`
      ) as HTMLSpanElement;
      if (taskDistance) {
        if (distance) {
          taskDistance.classList.remove('d-none');
          taskDistance.innerHTML = `Approx ${distance}m`;
        } else {
          taskDistance.classList.add('d-none');
        }
      }
    },
    []
  );

  const getLineDistance = (): Feature<LineString> | null => {
    const layers = map.getLayers().getArray();
    for (const layer of layers) {
      if (layer instanceof VectorLayer) {
        const source = layer.getSource();
        if (source instanceof VectorSource) {
          const features = source.getFeatures();
          for (const feature of features) {
            if (feature.getGeometry() instanceof LineString) {
              return feature as Feature<LineString>;
            }
          }
        }
      }
    }
    return null;
  };

  //#region Remove task line and distance
  const removeTaskDistance = React.useCallback(() => {
    const taskContainer = document.querySelectorAll('[id^="taskContainer-"]');
    taskContainer.forEach((task) => {
      task.classList.remove('expanded');
      const detailTaskContainer = task.querySelector(
        '[id^="detailTaskContainer-"]'
      );
      detailTaskContainer?.classList.remove('visible');
      detailTaskContainer?.classList.add('hidden');
      task.querySelector('[id^="taskDistance-"]')?.classList.add('d-none');
    });

    const layers = map.getAllLayers();
    const lineLayer = layers.find((layer) =>
      layer.getClassName().includes('line-string')
    );
    if (lineLayer) {
      // remove the line
      map.removeLayer(lineLayer);
    }
  }, [map]);

  //#region Add task line and distane
  const addTaskDistance = React.useCallback(
    (id: string, position: Coordinate | [number, number]) => {
      if (map) {
        // hide all tasks distance first
        removeTaskDistance();
        const taskContainer = document.getElementById(`taskContainer-${id}`);
        const detailTaskContainer = document.getElementById(
          `detailTaskContainer-${id}`
        );
        if (taskContainer && detailTaskContainer) {
          taskContainer.classList.add('expanded');
          detailTaskContainer.classList.add('visible');
          detailTaskContainer.classList.remove('hidden');
        }

        const playerLonLat = [
          player.playerState?.coordinates?.longitude || 0,
          player.playerState?.coordinates?.latitude || 0
        ];

        const distances = getDistance(position, playerLonLat);
        updateTaskOverlay({ id, distance: Math.floor(distances) });
        const lineFeature = new Feature({
          geometry: new LineString([
            fromLonLat(position),
            fromLonLat(playerLonLat)
          ])
        });

        const lineStyle = new Style({
          stroke: new Stroke({
            color: lineColor,
            width: 3,
            lineDash: [10, 10]
          })
        });

        const vectorSource = new VectorSource({
          features: [lineFeature]
        });

        const vectorLayer = new VectorLayer({
          source: vectorSource,
          style: lineStyle,
          className: 'line-string'
        });

        const layers = map.getAllLayers();
        const lineStringId = layers.findIndex((layer) =>
          layer.getClassName().includes('line-string')
        );

        if (lineStringId !== -1) {
          // if exist
          layers.splice(lineStringId, 1, vectorLayer);
        } else {
          layers.splice(1, 0, vectorLayer);
        }

        map.setLayers(layers);
      }
    },
    [
      map,
      lineColor,
      player.playerState?.coordinates?.latitude,
      player.playerState?.coordinates?.longitude,
      removeTaskDistance,
      updateTaskOverlay
    ]
  );

  const updateTaskPlayerListOverlay = React.useCallback(
    (
      id: string,
      playerItems: {
        id: string | undefined;
        avatarUrl: string | undefined;
        name: string | undefined;
        team: string;
      }[]
    ) => {
      const counter = document.getElementById(`taskPlayerCounter-${id}`);

      let newItems = cloneDeep(playerItems);
      const listById = document.getElementById(`taskPlayerList-${id}`);

      if (listById) {
        for (let i = 0; i < listById.children.length; i++) {
          const child = listById.children[i];
          const playerId = child
            .querySelector('[id^="avatar-"]')
            ?.id.replaceAll('avatar-', '');

          if (playerId) {
            document
              .querySelectorAll(`[id^="playerContainerItem-${playerId}"]`)
              .forEach((player) => {
                if (player.parentElement?.id !== `taskPlayerList-${id}`) {
                  player.remove();
                }
              });

            const player = playerItems.find((player) => player.id === playerId);
            if (player) {
              const { avatarUrl, name, team } = player;
              (child.querySelector('[id^="avatar-"]') as HTMLImageElement).src =
                avatarUrl || '';
              (
                child.querySelector('[id^="playerName-"]') as HTMLSpanElement
              ).innerHTML = name || '';

              const teamElement = child.querySelector(
                '[id^="playerTeam-"]'
              ) as HTMLSpanElement;
              if (team) {
                teamElement.classList.remove('d-none');
                teamElement.innerHTML = `${team}`;
              } else {
                teamElement.classList.add('d-none');
              }
            }

            newItems = newItems.filter((item) => item.id !== playerId);
          }
        }

        const newItemsElement = newItems.map((item) =>
          createPlayerItem({
            id: item.id || '',
            avatarUrl: item.avatarUrl || '',
            name: item.name || ''
          })
        );
        listById.append(...newItemsElement);

        if (counter) {
          const length = listById.children.length;
          counter.innerHTML = `${length} ${length > 1 ? 'players' : 'player'}`;
        }
      }

      if (
        mapIllustration &&
        game.gameState?.players &&
        game.gameState.players.length > 0
      ) {
        document
          .querySelectorAll(`[id^="taskPlayerList-"]`)
          .forEach((listContainer) => {
            const id = listContainer.id.replaceAll('taskPlayerList-', '');
            if (listContainer.children.length === 0) {
              const overlay = mapIllustration.getOverlayById(
                `player-list-${id}`
              );
              if (overlay) mapIllustration.removeOverlay(overlay);
            } else {
              const counter = document.getElementById(
                `taskPlayerCounter-${id}`
              );
              if (counter) {
                const length = listContainer.children.length;
                counter.innerHTML = `${length} ${length > 1 ? 'players' : 'player'}`;
              }
            }
          });
      }
    },
    [mapIllustration, game.gameState?.players]
  );

  const addTaskPlayerListOverlay = React.useCallback(
    (
      id: string,
      playerItems: {
        id: string | undefined;
        avatarUrl: string | undefined;
        name: string | undefined;
        team: string;
      }[]
    ) => {
      if (mapIllustration) {
        const listExist = mapIllustration.getOverlayById(`player-list-${id}`);
        if (listExist) {
          updateTaskPlayerListOverlay(id, playerItems);
          return;
        }

        const taskPosition = mapIllustration.getOverlayById(id)?.getPosition();
        if (taskPosition) {
          const playerItemsElement = playerItems.map((player) =>
            createPlayerItem({
              id: player.id || '',
              avatarUrl: player.avatarUrl || '',
              name: player.name || ''
            })
          );
          const taskPlayerContainer = createTaskPlayerContainer(
            id,
            playerItemsElement
          );
          const playerContainerOverlay = new Overlay({
            id: `player-list-${id}`,
            position: taskPosition,
            positioning: 'top-center',
            element: taskPlayerContainer,
            offset: [0, 38]
          });

          mapIllustration.addOverlay(playerContainerOverlay);
        }
      }
    },
    [mapIllustration]
  );

  return {
    hasTaskOverlay,
    addTaskOverlay,
    addTaskDistance,
    getLineDistance,
    updateTaskOverlay,
    removeTaskOverlay,
    removeTaskDistance,
    addTaskPlayerListOverlay
  };
};
