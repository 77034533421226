import { Container } from 'react-bootstrap';
import { useContext, useState } from 'react';
import { PlayerContext } from '../../../../../contexts/player';
import { DeleteAsync, PostAsync } from '../../../../../services/api';
import { UpdatePlayerContext } from '../../../../../utils/player-state';
import { GameDocumentContext } from '../../../../../contexts/game-document';
import TeamsList from './teams-list';
import TeamsView from './teams-view';
import SimpleEntity from '../../../../../components/tabs/simple-entity';
import { BuildNewTeamState, TeamContext } from '../../../../../contexts/team';
import { UpdateTeamContext } from '../../../../../utils/team-state';
import {
  addNewTeams,
  getTeamSelectedAsync
} from '../../../../../services/teams';
import { generateTitleById } from '../../../../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../../../../contexts/display-languages';

export const Teams = () => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [playerState, setPlayerState] = useContext(PlayerContext);
  const [viewingTeam, setViewingTeam] = useState<string | undefined>(
    playerState.playerState?.teamCode
  );
  const [teamState, setTeamState] = useContext(TeamContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const [teamError, setTeamError] = useState<string>('');
  const [teamDialogIsVisible, setTeamDialogIsVisible] =
    useState<boolean>(false);
  const toggleTeamsDialogHandler = () => {
    setTeamDialogIsVisible((prevState) => !prevState);
  };
  const showCreateTeam =
    gameDocument.gameDocument?.settings?.preGame?.showPreGameCreateTeams ??
    true;
  const showJoinLeaveTeam =
    gameDocument.gameDocument?.settings?.preGame?.showPreGameJoinLeaveTeams ??
    true;

  /* Join a team */
  const joinTeam = async (code: string) => {
    getTeamSelectedAsync(teamState.gameCode!, code).then((teamResponse) => {
      if (!teamResponse.isLocked) {
        PostAsync<any>(
          `games/${gameDocument.gameCode}/teams/${code}/players/${playerState.playerState?.code}`,
          null
        ).then((response) => {
          setViewingTeam(code);
          setPlayerState((prevState) =>
            UpdatePlayerContext(prevState, {
              ...prevState.playerState!,
              teamCode: code
            })
          );
        });

        getTeamSelectedAsync(playerState.gameCode!, code).then((res) => {
          setTeamState((prevTeam) =>
            UpdateTeamContext(prevTeam, {
              ...prevTeam.teamState!,
              code: code,
              avatarImage: res.avatar,
              name: res.name,
              groupCode: res.groupCode
            })
          );
        });
      }
    });
  };

  /* Leave a team */
  const leaveTeam = () => {
    getTeamSelectedAsync(
      teamState.gameCode!,
      playerState.playerState?.teamCode!
    ).then((teamResponse) => {
      if (!teamResponse.isLocked) {
        DeleteAsync<any>(
          `games/${gameDocument.gameCode}/teams/${playerState.playerState?.teamCode}/players/${playerState.playerState?.code}`
        ).then(() => {
          setViewingTeam(undefined);
          setPlayerState((prevState) =>
            UpdatePlayerContext(prevState, {
              ...prevState.playerState!,
              teamCode: undefined
            })
          );
          setTeamState((prevState) => ({
            ...prevState,
            teamState: BuildNewTeamState(gameDocument.gameCode!)
          }));
        });
      }
    });
  };

  const createTeam = () => {
    toggleTeamsDialogHandler();
  };

  const onSubmitHandler = (name?: string, avatar?: string) => {
    if (name === '') {
      setTeamError('Team name is required');
      return;
    } else if (name && (name.length < 2 || name.length > 128)) {
      setTeamError('Please enter a team name that is 2 - 128 characters');
      return;
    }

    addNewTeams(gameDocument.gameCode!, name!, avatar)
      .then((response: any) => {
        viewTeam(response.code);
        setTeamError('');
        toggleTeamsDialogHandler();
      })
      .catch(() => {
        setTeamError('Team name already exists');
      });
  };

  const viewTeam = (teamId: string | undefined) => {
    setViewingTeam(teamId);
  };

  return (
    <>
      {viewingTeam ? (
        <TeamsView
          onJoinTeam={joinTeam}
          onLeaveTeam={leaveTeam}
          teamCode={viewingTeam}
          onViewTeam={viewTeam}
          canJoinLeaveTeam={showJoinLeaveTeam}
        />
      ) : (
        <TeamsList
          onJoinTeam={joinTeam}
          onLeaveTeam={leaveTeam}
          onViewTeam={viewTeam}
          onCreateTeam={createTeam}
          canCreateTeams={showCreateTeam}
          canJoinLeaveTeam={showJoinLeaveTeam}
        />
      )}
      <Container className={'drawer__footer p-0 text-center pb-2'} />

      {teamDialogIsVisible && (
        <SimpleEntity
          inputTitle={
            generateTitleById(
              '5bc0109c-91f4-473e-94e1-99444faeaabf',
              gameDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'Team name'
          }
          saveBtnTitle={
            generateTitleById(
              '6db126df-9980-4fed-b80a-1d7a29dbb662',
              gameDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'Save'
          }
          errorMessage={teamError}
          onToggleProfileWidget={toggleTeamsDialogHandler}
          onSubmit={onSubmitHandler}
        />
      )}
    </>
  );
};
