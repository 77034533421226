import React, { useContext, useRef, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { CardSubtitle } from '@progress/kendo-react-layout';
import { Col, Row } from 'react-bootstrap';
import { dataURItoBlob, loadImageToBlobUrl } from '../../utils/image-helper';
import Webcam from 'react-webcam';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { FeedbackDocumentContext } from '../../contexts/feedback-document';
import { DisplayLanguageFeedbackContext } from '../../contexts/display-languages-feedback';

type Props = {
  isReadonly?: boolean;
  selectedImage?: string;
  onSelectedImageCallback?: (blobUrl: string | File, size?: number) => void;
};

const AnswerFile = ({
  isReadonly = false,
  selectedImage = '',
  onSelectedImageCallback = () => {}
}: Props) => {
  const [feedbackDocument] = useContext(FeedbackDocumentContext);
  const [feedbackDisplayLangContext] = useContext(
    DisplayLanguageFeedbackContext
  );
  const videoRef = useRef<Webcam>(null);
  const inputFile = useRef<HTMLInputElement>(null);
  const [blobImage, setBlobImage] = useState<string>(selectedImage);
  const [isDisplayWebcam, setIsDisplayWebcam] = useState<boolean>(false);
  const [imageExtension, setImageExtension] = useState<string>('');
  const [fileUpload, setFileUpload] = useState<File>();
  const handleSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const blobUrl = loadImageToBlobUrl(e.target.files![0]);
    const imageExt = e.target.files![0].name.split('.').pop();
    if (imageExt) {
      setImageExtension(imageExt);
    }
    setBlobImage(blobUrl);
    onSelectedImageCallback(e.target.files![0], e.target.files![0].size ?? 0);
    setFileUpload(e.target.files![0]);
  };
  const onAddImageClick = () => inputFile.current && inputFile.current.click();

  const onTakePictureClickHandler = () => {
    const screenShoot = videoRef.current?.getScreenshot();
    if (screenShoot && screenShoot !== '') {
      fetch(screenShoot)
        .then((result) => {
          const imageFile = dataURItoBlob(result.url);
          onSelectedImageCallback(imageFile as File, imageFile.size ?? 0);
          setBlobImage(result.url);
        })
        .catch((error) => console.error(error))
        .finally(() => setIsDisplayWebcam(false));
    }
  };

  const getFileExtension = (filename: string) => {
    // get file extension
    const extension = filename.substring(
      filename.lastIndexOf('.') + 1,
      filename.length
    );
    return extension;
  };
  const allowedImages = () => {
    return (
      getFileExtension(selectedImage) === 'png' ||
      getFileExtension(selectedImage) === 'jpg' ||
      getFileExtension(selectedImage) === 'jpeg' ||
      getFileExtension(selectedImage) === 'gif' ||
      getFileExtension(selectedImage) === ''
    );
  };

  const allowedVideo = () => {
    return (
      getFileExtension(selectedImage) === 'mp4' ||
      getFileExtension(selectedImage) === 'mov' ||
      getFileExtension(selectedImage) === 'wmv' ||
      getFileExtension(selectedImage) === 'avi'
    );
  };

  return (
    <div id={'answer-file'}>
      <Row className={'py-2'}>
        <Col>
          <div className={'border text-center h-100'}>
            {isDisplayWebcam ? (
              <Webcam
                id={'answer-webcam'}
                className={'py-2'}
                audio={false}
                videoConstraints={{
                  height: 480,
                  width: 480,
                  facingMode: 'user'
                }}
                screenshotFormat={'image/jpeg'}
                autoPlay={true}
                ref={videoRef}></Webcam>
            ) : (
              <>
                <div
                  className={
                    'd-flex justify-content-center align-items-center py-5'
                  }>
                  {blobImage === '' || selectedImage === '' ? (
                    <span
                      className={
                        'material-symbols-outlined text-success text-size-64'
                      }>
                      wallpaper
                    </span>
                  ) : allowedImages() ? (
                    <img
                      src={selectedImage ?? blobImage}
                      height={300}
                      alt={'Preview'}
                    />
                  ) : allowedVideo() ? (
                    <video
                      controls
                      className={'img-fluid img-thumbnail '}
                      key={selectedImage}>
                      <source
                        src={selectedImage}
                        type={`video/${
                          imageExtension !== ''
                            ? imageExtension
                            : getFileExtension(selectedImage)
                        }`}
                      />
                    </video>
                  ) : (
                    <p>
                      <span
                        className={
                          'material-symbols-outlined text-primary text-size-64'
                        }>
                        cloud_done
                      </span>
                      <br />
                      {!isReadonly ? (
                        fileUpload?.name ??
                        decodeURI(
                          selectedImage.substring(
                            selectedImage.lastIndexOf('/') + 1
                          )
                        )
                      ) : (
                        <>
                          <span>
                            {decodeURI(
                              selectedImage.substring(
                                selectedImage.lastIndexOf('/') + 1
                              )
                            )}
                          </span>{' '}
                          <br />
                          <a href={selectedImage}>Download</a>
                        </>
                      )}
                    </p>
                  )}
                </div>
                {blobImage === '' && (
                  <CardSubtitle>
                    {' '}
                    {generateTitleById(
                      '567963b7-47c0-40e9-8659-2e43a5a5befb',
                      feedbackDocument,
                      feedbackDisplayLangContext.displayLanguageSelected
                        .resources!,
                      'feedback'
                    ) || 'Preview'}
                  </CardSubtitle>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
      {!isReadonly && (
        <Row className={'py-2 justify-content-center'}>
          <Col md={6}>
            <Button
              className={'w-100 upload-button p-2 mb-2'}
              onClick={
                isDisplayWebcam ? onTakePictureClickHandler : onAddImageClick
              }>
              <div
                className={
                  'd-flex justify-content-center align-items-center gap-3'
                }>
                <span
                  className={'material-symbols-outlined mr-2 text-success'}
                  role={'button'}>
                  add_photo_alternate
                </span>
                <span>
                  {isDisplayWebcam
                    ? generateTitleById(
                        '7b63892e-236c-4b6a-bfec-d5d3d66794af',
                        feedbackDocument,
                        feedbackDisplayLangContext.displayLanguageSelected
                          .resources!,
                        'game'
                      ) || 'Take picture'
                    : generateTitleById(
                        'b6d7d97b-37bd-4ef9-aa9e-0e02a404a375',
                        feedbackDocument,
                        feedbackDisplayLangContext.displayLanguageSelected
                          .resources!,
                        'game'
                      ) || 'Add from files'}
                </span>
              </div>
            </Button>
            <input
              type={'file'}
              id={'answer-file-upload'}
              className={'d-none'}
              onChange={handleSelectImage}
              onClick={(event) => {
                event.currentTarget.value = '';
              }}
              ref={inputFile}
              accept={'*'}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AnswerFile;
