import { Col, Container, Row } from 'react-bootstrap';
import React, { useContext } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router-dom';
import { GameLogo } from '../../../../game-document/game-logo';
import { GameName } from '../../../../game-document/game-name';
import { GameHeader } from '../../../../game-document/game-header';
import { GameDescription } from '../../../../game-document/game-description';
import { GameMedia } from '../../../../game-document/game-media';
import { GameContext } from '../../../../../contexts/game';
import { GameStatus } from '../../../../../types/state';
import { OverlayContext } from '../../../../../contexts/overlay';
import { PlayerContext } from '../../../../../contexts/player';
import { UpdatePlayerContext } from '../../../../../utils/player-state';
import { GameDocumentContext } from '../../../../../contexts/game-document';
import { generateTitleById } from '../../../../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../../../../contexts/display-languages';

export const Info = () => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [game] = useContext(GameContext);
  const [overlay, setOverlay] = useContext(OverlayContext);
  const [player, setPlayer] = useContext(PlayerContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const gameStatus: GameStatus = game.gameState?.status ?? 'pre-game';
  const navigate = useNavigate();
  const handleEnterGame = () => {
    setOverlay((prevState) => ({
      ...prevState,
      activeTab: 'info',
      drawerIsOpen: false
    }));

    setPlayer((prev) =>
      UpdatePlayerContext(prev, {
        ...prev.playerState!,
        status: 'playing',
        joinedDate: !prev?.playerState?.joinedDate
          ? new Date()
          : prev?.playerState?.joinedDate
      })
    );

    navigate('map');
  };

  const disableEnterGame = () => {
    const firstCondition = !(
      game.gameState?.status === 'starting' ||
      game.gameState?.status === 'running'
    );
    const currentPlayer = game.gameState?.players.find(
      (item) => player.playerState?.code === item.code
    );
    const secondCondition = gameDocument.gameDocument?.settings.preGame
      .isPreGameTeamRequired
      ? currentPlayer?.teamCode === '00000000-0000-0000-0000-000000000000' ||
        !currentPlayer?.teamCode
      : false;

    return firstCondition || secondCondition;
  };

  return (
    <>
      <Container className={'drawer__body'}>
        <Row>
          <GameHeader />
        </Row>
        <Row>
          <Col className={'py-3 bg-success position-relative'}>
            <GameLogo
              className={
                'event-image position-absolute w-5 h-5 rounded-circle border border-5 border-dark bg-white'
              }
            />
            <p className={'mb-0 pl-12 ml-20  text-white'}>
              <strong>
                <GameName />
              </strong>
            </p>
          </Col>
        </Row>
        <Row className={'bg-white pt-5 pb-4'}>
          <Col xs={12} md={8} className={'ps-4'}>
            <GameDescription />
          </Col>
          <Col xs={12} md={4}>
            <GameMedia className={'me-2'} />
          </Col>
        </Row>
      </Container>
      <Container className={'drawer__footer p-0 text-center pb-2 pt-2'}>
        <Row>
          <Col>
            <Button
              className={'w-100'}
              themeColor={
                gameStatus === 'starting' || gameStatus === 'running'
                  ? 'success'
                  : 'base'
              }
              size={'large'}
              onClick={handleEnterGame}
              disabled={disableEnterGame()}>
              {generateTitleById(
                'e4d66a9d-ed75-443e-82f1-9a8da53325d8',
                gameDocument,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Enter game'}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
