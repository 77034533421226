import * as signalR from '@microsoft/signalr';

class StateConnector {
  private connection: signalR.HubConnection;

  public events: (
    onMessageReceived: (message?: string) => void
  ) => void;

  static instance: StateConnector;

  constructor(url: string, token: string, method: string) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: () => token
      })
      .withAutomaticReconnect()
      .build();

    this.events = (onMessageReceived) => {
      this.connection.on(method, ( message) => {
        onMessageReceived(message);
      });
    };

    this.connection.start().catch((err) => document.write(err));
  }
}

export default StateConnector;
