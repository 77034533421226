import React, { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { GameState, GameStatus } from '../types/state';
import { uuid } from '../types/common-helper';
import { GetGamesHub, postBroadcastGameStateAsync } from '../services/games';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { HubResponse } from '../types/responses/hub-response';
import { isEmpty, pick } from 'lodash';
import { UpdateGameStateAsync } from '../utils/game-engine/base';
import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';
import { PlayerContextState } from './player';
import ListenerManager from '../utils/listener-manager';

const GameContext = React.createContext<
  [
    initialState: GameContextState,
    setState: React.Dispatch<React.SetStateAction<GameContextState>>
  ]
>([{ isLoaded: false, isDirty: false }, () => {}]);

interface GameStateProviderProps {
  gameCode: string;
  children: React.ReactNode;
}

const GameStateProvider = ({ gameCode, ...props }: GameStateProviderProps) => {
  const [player] = useLocalStorage<PlayerContextState>(`${gameCode}-player`, {
    gameCode: gameCode,
    isLoaded: false,
    isDirty: false
  });
  const [state, setState] = useLocalStorage<GameContextState>(
    `${gameCode}-state`,
    {
      gameCode: gameCode,
      gameState: BuildNewGameState(gameCode),
      isLoaded: false,
      isDirty: false,
      activityState: {
        hasGame: true,
        hasAssessment: true,
        hasFeedback: false,
        eventName: ''
      }
    }
  );

  const [GameStateConnection, setGameStateConnection] =
    useState<HubConnection>();
  const [GameHubResponse, setGameHubResponse] = useState<HubResponse>();

  const gameStateConnection = async () => {
    try {
      const hub = await GetGamesHub(gameCode);
      setGameHubResponse(hub);
      if (hub.activityState) {
        const hasGame: boolean = hub.activityState.hasGame;
        setState((prev) => ({
          ...prev,
          // set game state to undefined if does not has game
          ...(!hasGame && { gameState: undefined }),
          activityState: hub.activityState
        }));

        // create connection if has game
        if (hasGame) {
          const connectionHub = new HubConnectionBuilder()
            .withUrl(`${hub.endpointUrl}`, {
              accessTokenFactory: () => hub.accessToken!
            })
            .withAutomaticReconnect()
            .build();
          setGameStateConnection(connectionHub);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateGameStatus = (message: string): void => {
    const messageParse: any = JSON.parse(message);
    let status: GameStatus = 'pre-game';
    let isResume: boolean = false;
    let isUpdateState: boolean = true;
    switch (messageParse?.action) {
      case 'GameStarted':
        status = 'starting';
        break;
      case 'GamePaused':
        status = 'paused';
        break;
      case 'GameResumed':
        status = 'running';
        isResume = true;
        break;
      case 'GameStopped':
        status = 'finished';
        break;
      case 'GameStateUpdated':
        status = messageParse?.gameState?.status;
        isResume = messageParse?.gameState?.isResume;
        break;
      default:
        isUpdateState = false;
        break;
    }

    if (isUpdateState)
      setState((prev) =>
        UpdateGameStateAsync(
          prev,
          cloneDeep({
            ...prev.gameState!,
            isResume,
            status
          })
        )
      );
  };

  const updatePlayerSubmittedStatus = (message: string): void => {
    const messageParse: any = JSON.parse(message);
    let isUpdateState: boolean = true;
    let hasNewSubmittedAnswer: boolean = false;
    switch (messageParse?.action) {
      case 'PlayerSubmitAnswer':
        hasNewSubmittedAnswer = true;
        break;
      default:
        isUpdateState = false;
        break;
    }

    if (isUpdateState)
      setState((prev) =>
        UpdateGameStateAsync(
          prev,
          cloneDeep({
            ...prev.gameState!,
            hasNewSubmittedAnswer
          })
        )
      );
  };

  const updateGameState = (message: string): void => {
    const messageParse: any = JSON.parse(message);
    if (messageParse && messageParse.gameState) {
      // when cloning the game state object from server, have to ignore 2 fields as it is only used locally
      const acceptFields = ['isPreloadCompleted', 'totalAssets'];

      setState((prev) => {
        // pick acceptFields with value, then merge into gamestate from server
        const newGameStatus = pick(prev.gameState, acceptFields);

        const gameState: GameState = merge(
          { ...prev.gameState, ...messageParse.gameState },
          newGameStatus
        );
        return UpdateGameStateAsync(prev, gameState);
      });
    }
  };

  const GameFacilitatorContentHandler = async (data: any) => {
    setState((prev) => ({
      ...prev,
      contentId: data.id
    }));
  };

  const GameConnectionListener = async (connection: HubConnection) => {
    connection.on(`${GameHubResponse?.method}`, (message) => {
      if (!isEmpty(message)) {
        try {
          const messageParse: any = JSON.parse(message);

          if (player && messageParse.action === 'GameFacilitatorContent') {
            const listener = new ListenerManager({
              GameFacilitatorContent: GameFacilitatorContentHandler
            });
            listener.listen(message);
          }

          if (messageParse && messageParse.gameState) {
            updateGameState(message);
          } else {
            updateGameStatus(message);
          }

          if (messageParse && messageParse.action === 'PlayerSubmitAnswer') {
            updatePlayerSubmittedStatus(message);
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
    connection.onclose((e) => {
      //console.error(e);
    });

    await connection
      .start()
      .then(() => {
        postBroadcastGameStateAsync(gameCode);
      })
      .catch(() => {
        // do nothing
      });
  };

  useEffect(() => {
    if (!state.isLoaded) {
      setState((state) => ({
        ...state,
        isLoaded: true,
        gameState: BuildNewGameState(gameCode),
        activityState: {
          hasGame: true,
          hasAssessment: true,
          hasFeedback: false,
          eventName: state.activityState?.eventName || ''
        }
      }));
    }
  }, []);

  useEffect(() => {
    if (!gameCode) return;
    if (GameHubResponse) return;
    gameStateConnection();
  }, [gameCode, GameHubResponse]);

  useEffect(() => {
    if (GameStateConnection) {
      GameConnectionListener(GameStateConnection);
    }
    return () => {
      GameStateConnection?.stop();
    };
  }, [GameStateConnection]);

  return (
    <GameContext.Provider value={[state, setState]}>
      {props.children}
    </GameContext.Provider>
  );
};

interface activityState {
  hasGame: boolean;
  hasAssessment: boolean;
  hasFeedback: boolean;
  eventName: string;
}
export interface GameContextState {
  gameCode?: string;
  gameState?: GameState;
  activityState?: activityState;
  isLoaded?: boolean;
  isDirty: boolean;
  contentId?: string;
}

export const BuildNewGameState = (gameCode: string): GameState => {
  return {
    id: uuid(),
    name: '',
    code: gameCode,
    status: 'pre-game',
    isPreloadCompleted: false,
    hasNewSubmittedAnswer: false,
    totalAssets: 0,
    pausedStatus: undefined,
    elapsedTime: [],
    isResume: undefined,
    startedUtc: undefined,
    finishedUtc: undefined,
    teams: [],
    players: []
  };
};

export { GameContext, GameStateProvider };
