import { Col, Container, Row } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import React, { useContext, useEffect, useState } from 'react';
import { PlayerContext } from '../../../../../contexts/player';
import { TeamResponse } from '../../../../../types/responses/team-response';
import { PlayerResponse } from '../../../../../types/responses/player-response';
import { GetAllAsync, GetAsync } from '../../../../../services/api';
import { CardList } from '../../../../../components/card-list';
import { Card } from '../../../../../components/card';
import ColLoader from '../../../../../components/col-loader';
import MaterialIcon from '../../../../../components/material-icon';
import { IsTeamLocked } from '../../../../../utils/game-state';
import { GameContext } from '../../../../../contexts/game';
import {
  ChatBetweenPlayer,
  PostChatBetweenPlayer
} from '../../../../../services/players';
import { OverlayContext } from '../../../../../contexts/overlay';
import { generateTitleById } from '../../../../../utils/game-document/display-languages';
import { GameDocumentContext } from '../../../../../contexts/game-document';
import { DisplayLanguageContext } from '../../../../../contexts/display-languages';

export interface TeamsViewProps {
  teamCode: string;
  onJoinTeam: (teamId: string) => void;
  onLeaveTeam: () => void;
  onViewTeam: (teamId: string | undefined) => void;
  canJoinLeaveTeam: boolean;
}

interface TeamModel {
  team: TeamResponse;
  players: PlayerResponse[];
}

export const TeamsView = ({
  teamCode,
  onJoinTeam,
  onLeaveTeam,
  onViewTeam,
  canJoinLeaveTeam = true
}: TeamsViewProps) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [playerState] = useContext(PlayerContext);
  const [gameContext] = useContext(GameContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const [team, setTeam] = useState<TeamModel>();
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [, setOverlay] = useContext(OverlayContext);
  const chatPlayer = async (toPlayerCode: string) => {
    let payload: ChatBetweenPlayer = {
      fromPlayerCode: playerState?.playerState!.code!,
      toPlayerCode: toPlayerCode,
      message: ''
    };

    await PostChatBetweenPlayer(gameContext?.gameCode!, payload);

    setOverlay((prevState) => ({
      ...prevState,
      activeTab: 'chat',
      drawerIsOpen: true
    }));
  };

  // load the team whenever the teamCode or the players teamCode changes
  useEffect(() => {
    let isSubscribed = true;
    const fetchTeam = async () => {
      // get the selected team record
      let teamResponse = await GetAsync<TeamResponse>(
        `games/${playerState.gameCode}/teams/${teamCode}`
      );

      // get all players on the selected team
      let playersResponse = await GetAllAsync<PlayerResponse>(
        `games/${playerState.gameCode}/teams/${teamCode}/players`
      );

      // sort players alphabetically
      let playersData = playersResponse.data.sort((a, b) =>
        a.name!.localeCompare(b.name!)
      );

      // move the current player to the start, if they are on the team...
      // TODO: Gotta be a better way...
      if (
        playerState.playerState?.code &&
        teamCode === playerState.playerState.teamCode
      ) {
        let myPlayerIndex = playersData.findIndex(
          (i) => i.code === playerState.playerState?.code
        );
        let myPlayer = playersData.splice(myPlayerIndex, 1);
        playersData = myPlayer.concat(playersData);
      }

      if (isSubscribed) {
        setTeam({ team: teamResponse, players: playersData });
      }
    };
    fetchTeam().catch(console.error);
    return () => {
      isSubscribed = false;
    };
  }, [teamCode, playerState.playerState?.teamCode]);

  return (
    <>
      <Container className={'drawer__body'}>
        {!team ? (
          <ColLoader />
        ) : (
          <>
            <Row className={'mt-3 mb-4 align-items-center border-bottom pb-3'}>
              <Col md={'auto'}>
                <Button
                  className={'k-button--icon'}
                  themeColor={'primary'}
                  fillMode={'flat'}
                  onClick={() => onViewTeam(undefined)}>
                  <MaterialIcon icon={'arrow_back_ios'} />
                </Button>
              </Col>
              <Col>
                <h1 className={'m-0'}>
                  {team.team.group?.name
                    ? team.team.group?.name! + ' - ' + team.team.name
                    : team.team.name!}
                </h1>
              </Col>
              {canJoinLeaveTeam && (
                <Col sm={12} md={'auto'}>
                  <Button
                    className={'k-button--icon'}
                    themeColor={'primary'}
                    fillMode={'flat'}
                    onClick={forceUpdate}
                    title={'Refresh players list'}>
                    <MaterialIcon icon={'sync'} />
                  </Button>
                  {teamCode === playerState.playerState?.teamCode ? (
                    <Button
                      className={'k-button--gradient'}
                      themeColor={'primary'}
                      onClick={onLeaveTeam}
                      disabled={IsTeamLocked(gameContext.gameState!, teamCode)}>
                      {generateTitleById(
                        '983f048a-ec82-47fd-9f7e-c59ab3526d5a',
                        gameDocument,
                        displayLanguageContext.displayLanguageSelected
                          .resources!,
                        'game'
                      ) || 'Leave team'}
                    </Button>
                  ) : (
                    <Button
                      themeColor={
                        playerState.playerState?.teamCode ? 'base' : 'primary'
                      }
                      className={`${
                        playerState.playerState?.teamCode
                          ? ''
                          : 'k-button--gradient'
                      }`}
                      onClick={() => onJoinTeam(teamCode)}
                      disabled={IsTeamLocked(gameContext.gameState!, teamCode)}>
                      {generateTitleById(
                        '66883910-7d5d-4858-8ca0-f433072cc57e',
                        gameDocument,
                        displayLanguageContext.displayLanguageSelected
                          .resources!,
                        'game'
                      ) || 'Join team'}
                    </Button>
                  )}
                </Col>
              )}
            </Row>

            <Row>
              <Col xs={12}>
                <CardList>
                  {team?.players &&
                    team.players?.map((player, index) => (
                      <div className={'d-flex flex-column'}>
                        <Card
                          key={index}
                          imageUrl={
                            player.avatar ?? 'https://via.placeholder.com/200'
                          }
                          title={player.name ?? ''}
                          subTitle={
                            generateTitleById(
                              '49aee67e-8bc4-4680-98f4-03d4636bcc73',
                              gameDocument,
                              displayLanguageContext.displayLanguageSelected
                                .resources!,
                              'game'
                            ) || 'Team member'
                          }>
                          {player.code !== playerState?.playerState?.code && (
                            <div
                              onClick={() => {
                                chatPlayer(player?.code!);
                              }}
                              className="d-flex justify-content-center cursor-pointer">
                              <span
                                className={
                                  'material-symbols-outlined material-symbols-outlined--outlined mr-1 fw-bold'
                                }>
                                chat
                              </span>
                              <small className={'fw-bold'}>Send message</small>
                            </div>
                          )}
                        </Card>
                      </div>
                    ))}
                </CardList>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default TeamsView;
