import React, { useContext, useEffect, useState } from 'react';
import {
  Chat,
  ChatMessageSendEvent,
  Message
} from '@progress/kendo-react-conversational-ui';
import MessageChat from './message-chat';
import { ChatContext } from '../../contexts/chat';
import {
  GetTeamChatHistory,
  PostChat,
  PostTeamChat,
  getTeamSelectedAsync
} from '../../services/teams';
import { ChatState } from '../../types/state/websocket/chat-state';
import {
  GameChat,
  GetGameChatHistory,
  PostGameChat
} from '../../services/games';
import { Button } from '@progress/kendo-react-buttons';
import { GameContext } from '../../contexts/game';
import { PlayerContext } from '../../contexts/player';
import { ShowGameChat, ShowTeamChat } from '../../utils/game-engine/player';
import { GameDocumentContext } from '../../contexts/game-document';
import { TeamContext } from '../../contexts/team';
import { GetGroupChatHistory, PostGroupChat } from '../../services/groups';
import { GetTeamSelectedResponse } from '../../types/responses/team-response';
import {
  ChatBetweenPlayer,
  ChatToFacilitator,
  GetChatHistoryBetweeenPlayersAsync,
  GetChatWithPlayersAsync,
  GetPlayerFacilitatorChatHistoryAsync,
  PostChatBetweenPlayer,
  PostChatFacilitator
} from '../../services/players';
import {
  isExistUnreadChat,
  removeUnreadChat
} from '../../utils/game-engine/chat';
import { PlayerResponse } from '../../types/responses/player-response';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../contexts/display-languages';

const ChatWindow = () => {
  const [gameDocumentstate] = React.useContext(GameDocumentContext);
  const [gameState] = useContext(GameContext);
  const [playerState] = useContext(PlayerContext);
  const [teamState] = useContext(TeamContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const notifIcon = 'k-icon k-i-circle text-danger';
  const [chatStateContext] = useContext(ChatContext);
  const [messages, setMessages] = useState<ChatState[]>([]);
  const user = {
    id: playerState?.playerState?.code,
    avatarUrl: playerState?.playerState?.avatarImage
  };
  const [visibleButtonChat, setVisibleButtonChat] = useState<boolean>(true);
  const [allPlayersGroup, setAllPlayersGroup] = React.useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>('All Player');
  const [groupCode, setGroupCode] = useState<string>('');
  const [chatContacts, setChatContacts] = useState<PlayerResponse[]>([]);
  const addNewMessage = async (event: ChatMessageSendEvent) => {
    if (!visibleButtonChat) return;
    try {
      if (activeTab === 'All Player') {
        if (gameState?.gameCode) {
          let postChat: GameChat = {
            playerCode: playerState?.playerState?.code ?? '',
            gameCode: playerState?.gameCode!,
            message: event.message.text ?? ''
          };
          await PostGameChat(gameState?.gameCode, postChat);
        }
      } else if (activeTab === 'Team') {
        if (
          playerState?.playerState?.teamCode &&
          playerState?.playerState?.teamCode !== '' &&
          playerState?.playerState?.teamCode !==
            '00000000-0000-0000-0000-000000000000'
        ) {
          let postChat: PostChat = {
            playerCode: playerState?.playerState?.code ?? '',
            teamCode: playerState?.playerState?.teamCode,
            message: event.message.text ?? '',
            sentDateUtc: new Date().toISOString()
          };
          await PostTeamChat(
            playerState?.playerState?.teamCode,
            gameState.gameCode!,
            postChat
          );
        }
      } else if (activeTab === 'Group') {
        if (
          playerState?.playerState?.teamCode &&
          playerState?.playerState?.teamCode !== '' &&
          playerState?.playerState?.teamCode !==
            '00000000-0000-0000-0000-000000000000'
        ) {
          const teamState = (await getTeamSelectedAsync(
            gameState.gameCode!,
            playerState?.playerState?.teamCode
          )) as GetTeamSelectedResponse;

          let postChat: PostChat = {
            playerCode: playerState?.playerState?.code ?? '',
            teamCode: playerState?.playerState?.teamCode,
            message: event.message.text ?? '',
            sentDateUtc: new Date().toISOString()
          };
          await PostGroupChat(
            teamState.groupCode!,
            gameState.gameCode!,
            postChat
          );
        }
      } else if (activeTab === 'Facilitators') {
        let payload: ChatToFacilitator = {
          fromPlayerCode: playerState?.playerState?.code ?? '',
          message: event.message.text ?? ''
        };

        await PostChatFacilitator(gameState.gameCode!, payload);
      } else {
        let payload: ChatBetweenPlayer = {
          fromPlayerCode: playerState?.playerState!.code!,
          toPlayerCode: activeTab,
          message: event.message.text ?? ''
        };

        await PostChatBetweenPlayer(gameState?.gameCode!, payload);

        let newMessages: Message[] = [...messages];
        newMessages.push({
          author: {
            id: playerState?.playerState?.code,
            name: playerState?.playerState?.name ?? '',
            avatarUrl: playerState?.playerState?.avatarImage ?? ''
          },
          timestamp: new Date(),
          text: event.message.text ?? ''
        });

        setMessages(newMessages);
      }
    } catch (error) {
      console.log('There is an error when sending chat: ', error);
    }
  };

  const getGameChatHistory = () => {
    let newChats: ChatState[] = [];
    GetGameChatHistory(gameState?.gameCode!).then((response) => {
      response?.data.forEach((chat) => {
        newChats.push({
          author: {
            id: chat.playerCode,
            name: chat.playerName ?? '',
            avatarUrl: chat.playerAvatar ?? ''
          },
          timestamp: new Date(chat.sentDateUtc),
          group: chat.gameCode,
          text: chat.message
        });
      });
      setMessages(newChats);
    });
  };

  const getTeamChatHistory = () => {
    let newChats: ChatState[] = [];
    if (
      playerState?.playerState?.teamCode &&
      playerState?.playerState?.teamCode !==
        '00000000-0000-0000-0000-000000000000'
    ) {
      GetTeamChatHistory(
        playerState?.playerState?.teamCode,
        gameState.gameCode!
      ).then((response) => {
        response?.data.forEach((chat) => {
          newChats.push({
            author: {
              id: chat.playerCode,
              name: chat.playerName ?? '',
              avatarUrl: chat.playerAvatar ?? ''
            },
            timestamp: new Date(chat.sentDateUtc),
            group: playerState?.playerState?.teamCode,
            text: chat.message
          });
        });
        setMessages(newChats);
      });
    } else {
      setMessages([]);
    }
  };

  const getGroupChatHistory = async () => {
    let newChats: ChatState[] = [];
    if (
      playerState?.playerState?.teamCode &&
      playerState?.playerState?.teamCode !==
        '00000000-0000-0000-0000-000000000000'
    ) {
      const teamState = (await getTeamSelectedAsync(
        gameState.gameCode!,
        playerState?.playerState?.teamCode
      )) as GetTeamSelectedResponse;

      setGroupCode(teamState.groupCode!);
      GetGroupChatHistory(teamState.groupCode!, gameState.gameCode!).then(
        (response) => {
          response?.data.forEach((chat) => {
            newChats.push({
              author: {
                id: chat.playerCode,
                name: chat.playerName ?? '',
                avatarUrl: chat.playerAvatar ?? ''
              },
              timestamp: new Date(chat.sentDateUtc),
              group: teamState.groupCode,
              text: chat.message
            });
          });
          setMessages(newChats);
        }
      );
    } else {
      setMessages([]);
    }
  };

  const getFacilitatorChatAsync = async () => {
    let newChats: ChatState[] = [];
    GetPlayerFacilitatorChatHistoryAsync(
      gameState?.gameCode!,
      playerState?.playerState!.code!
    ).then((response) => {
      response?.forEach((chat) => {
        newChats.push({
          author: {
            id: chat.playerCode,
            name: chat.playerName ?? '',
            avatarUrl: chat.playerAvatar ?? ''
          },
          timestamp: new Date(chat.sentDateUtc),
          group: 'Facilitators',
          text: chat.message
        });
      });
      setMessages(newChats);
    });
  };

  const getChatBetweenPlayerAsync = async (chatWithPlayerCode: string) => {
    let newChats: ChatState[] = [];
    GetChatHistoryBetweeenPlayersAsync(
      gameState?.gameCode!,
      playerState?.playerState!.code!,
      chatWithPlayerCode
    ).then((response) => {
      response?.forEach((chat) => {
        newChats.push({
          author: {
            id: chat.playerCode,
            name: chat.playerName ?? '',
            avatarUrl: chat.playerAvatar ?? ''
          },
          timestamp: new Date(chat.sentDateUtc),
          group: chatWithPlayerCode,
          text: chat.message
        });
      });
      setMessages(newChats);
    });
  };

  const handleSwitch = (
    chatType: string,
    unreadCode: string,
    withPlayerCode?: string
  ) => {
    removeUnreadChat(gameState?.gameCode!, unreadCode);

    setActiveTab(chatType);

    if (chatType === 'All Player') {
      getGameChatHistory();
    } else if (chatType === 'Team') {
      getTeamChatHistory();
    } else if (chatType === 'Group') {
      getGroupChatHistory();
    } else if (chatType === 'Facilitators') {
      getFacilitatorChatAsync();
    } else {
      getChatBetweenPlayerAsync(withPlayerCode!);
    }
  };

  const handleChatVisibility = () => {
    if (allPlayersGroup) {
      let visible = ShowGameChat(
        playerState?.playerState!,
        gameDocumentstate?.gameDocument!
      );
      setVisibleButtonChat(visible!);
    } else {
      let visible = ShowTeamChat(
        playerState?.playerState!,
        gameDocumentstate?.gameDocument!
      );
      setVisibleButtonChat(visible!);
    }
  };

  const handleEnabledDisabledTeamChat = () => {
    setVisibleButtonChat(!teamState.teamState?.isChatDisabled!);
  };

  const GetChatContactAsync = async () => {
    let contacts = await GetChatWithPlayersAsync(
      gameState?.gameCode!,
      playerState?.playerState?.code!
    );

    if (contacts) setChatContacts(contacts);
  };

  useEffect(() => {
    if (
      (activeTab === 'All Player' &&
        chatStateContext.group === gameState?.gameCode) ||
      (activeTab === 'Team' &&
        chatStateContext.group === playerState?.playerState?.teamCode) ||
      (activeTab === 'Group' &&
        chatStateContext.group === teamState.teamState?.groupCode) ||
      (activeTab === 'Facilitators' &&
        chatStateContext.group === 'Facilitators') ||
      (activeTab === 'Facilitators' &&
        chatStateContext.group === playerState?.playerState?.code) ||
      activeTab === chatStateContext.group ||
      chatStateContext.group === playerState?.playerState?.code
    ) {
      let newMessages: Message[] = [...messages];
      newMessages.push(chatStateContext);
      setMessages(newMessages);
    }

    if (activeTab === 'All Player') {
      removeUnreadChat(
        gameState?.gameCode!,
        `GlobalChat_${gameDocumentstate?.gameCode}`
      );
    } else if (activeTab === 'Team') {
      removeUnreadChat(
        gameState?.gameCode!,
        `TeamChat_${teamState.teamState?.code}`
      );
    } else if (activeTab === 'Group') {
      removeUnreadChat(
        gameState?.gameCode!,
        `GroupChat_$${teamState.teamState?.groupCode}`
      );
    } else if (activeTab === 'Facilitators') {
      removeUnreadChat(
        gameState?.gameCode!,
        `PlayerChat_${playerState?.playerState?.code}_Facilitator`
      );
    } else if (activeTab === chatStateContext.group) {
      removeUnreadChat(
        gameState?.gameCode!,
        `PlayerChat_${playerState?.playerState?.code}_${chatStateContext.group}`
      );
    }
  }, [chatStateContext]);

  useEffect(() => {
    handleChatVisibility();
  }, [playerState?.playerState?.status]);

  useEffect(() => {
    handleEnabledDisabledTeamChat();
  }, [teamState.teamState?.isChatDisabled]);

  useEffect(() => {
    setActiveTab('All Player');
    getGameChatHistory();
    GetChatContactAsync();
  }, []);

  return (
    <>
      <div>
        <div className="d-flex align-items-center gap-3 mt-3 mb-3">
          <h4 className="text-dark m-0">
            {generateTitleById(
              'e20069a1-1a00-4183-b62e-7375d02b4721',
              gameDocumentstate,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'Chat with'}
          </h4>
          <div className="example-wrapper">
            <Button
              className={'mr-1'}
              onClick={() =>
                handleSwitch(
                  'All Player',
                  `GlobalChat_${gameDocumentstate?.gameCode}`
                )
              }
              themeColor={activeTab === 'All Player' ? 'dark' : 'light'}>
              {generateTitleById(
                'bd2f061c-b665-4fe5-84ce-b986a1008a76',
                gameDocumentstate,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'All players'}
              {isExistUnreadChat(
                gameDocumentstate?.gameCode!,
                `GlobalChat_${gameDocumentstate?.gameCode}`
              ) && <span className={notifIcon}></span>}
            </Button>
            <Button
              className={'mr-1'}
              onClick={() =>
                handleSwitch('Team', `TeamChat_${teamState.teamState?.code}`)
              }
              themeColor={activeTab === 'Team' ? 'dark' : 'light'}>
              {generateTitleById(
                '6a6f31b6-fb91-42f8-acab-f469e0ef1d86',
                gameDocumentstate,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'My team'}
              {isExistUnreadChat(
                gameDocumentstate?.gameCode!,
                `TeamChat_${teamState.teamState?.code}`
              ) && <span className={notifIcon}></span>}
            </Button>
            <Button
              className={'mr-1'}
              onClick={() =>
                handleSwitch(
                  'Group',
                  `GroupChat_${teamState.teamState?.groupCode}`
                )
              }
              themeColor={activeTab === 'Group' ? 'dark' : 'light'}>
              {generateTitleById(
                '8fd3fb0f-e237-4717-9272-98a84f4e9af4',
                gameDocumentstate,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'My group'}
              {isExistUnreadChat(
                gameDocumentstate?.gameCode!,
                `GroupChat_${teamState.teamState?.groupCode}`
              ) && <span className={notifIcon}></span>}
            </Button>
            <Button
              className={'mr-1'}
              onClick={() =>
                handleSwitch(
                  'Facilitators',
                  `PlayerChat_${playerState?.playerState?.code}_Facilitator`
                )
              }
              themeColor={activeTab === 'Facilitators' ? 'dark' : 'light'}>
              {generateTitleById(
                'fe72a8db-b666-4720-b08c-6081605cd24c',
                gameDocumentstate,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Facilitators'}
              {isExistUnreadChat(
                gameDocumentstate?.gameCode!,
                `PlayerChat_${playerState?.playerState?.code}_Facilitator`
              ) && <span className={notifIcon}></span>}
            </Button>

            {chatContacts?.map((item) => {
              return (
                <>
                  <Button
                    className={'mr-1'}
                    onClick={() =>
                      handleSwitch(
                        `${item.code}`,
                        `PlayerChat_${playerState?.playerState?.code}_${item.code}`,
                        `${item.code!}`
                      )
                    }
                    themeColor={
                      activeTab === `${item.code}` ? 'dark' : 'light'
                    }>
                    {item?.name}
                    {isExistUnreadChat(
                      gameDocumentstate?.gameCode!,
                      `PlayerChat_${playerState?.playerState?.code}_${item.code}`
                    ) && <span className={notifIcon}></span>}
                  </Button>
                </>
              );
            })}
          </div>
        </div>
        <div className="w-full mt-4 h-full">
          <Chat
            className={!visibleButtonChat ? 'hide-action' : ''}
            user={user}
            messages={messages}
            onMessageSend={addNewMessage}
            placeholder={
              generateTitleById(
                '2373ccd3-e73e-403d-bd99-7083f1512a96',
                gameDocumentstate,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Type a message...'
            }
            message={MessageChat}
          />
        </div>
      </div>
    </>
  );
};

export default ChatWindow;
