import React, { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { GetPlayerHub } from '../services/players';
import StateConnector from '../services/state-connector';
import { PlayerStateFull } from '../types/state';
import { PlayerAssessmentState } from '../types/state/player/player-assessment';
import { DefaultDisplayLanguage } from '../constants/display-languages';

const PlayerContext = React.createContext<
  [
    initialState: PlayerContextState,
    setState: React.Dispatch<React.SetStateAction<PlayerContextState>>
  ]
>([{ isLoaded: false, isDirty: false }, () => {}]);

interface PlayerStateProviderProps {
  gameCode: string;
  children: React.ReactNode;
}

const PlayerStateProvider = ({
  gameCode,
  ...props
}: PlayerStateProviderProps) => {
  const [state, setState] = useLocalStorage<PlayerContextState>(
    `${gameCode}-player`,
    {
      gameCode: gameCode,
      playerState: BuildNewPlayerState(gameCode),
      isLoaded: false,
      isDirty: false
    }
  );

  const [hubConnected, setHubConnected] = useState<boolean>(false);

  const createStateConnection = () => {
    const playerCode = state.playerState?.code!;

    // don't make a connection when the player code empty and hubConnected = true
    if (!playerCode || hubConnected) return;

    GetPlayerHub(playerCode)
      .then((response) => {
        if (response) {
          let conn = new StateConnector(
            response.endpointUrl,
            response.accessToken,
            response.method
          );
          if (conn) {
            conn.events((message) => {
              if (message) {
                setState(JSON.parse(message));
              }
            });
            setHubConnected(true);
          }
        }
      })
      .catch((e) => {
        if (e instanceof Error) {
          console.error(e.message);
        }
        throw e;
      });
  };

  useEffect(() => {
    if (!state.isLoaded) {
      setState((state) => ({
        ...state,
        isLoaded: true,
        playerState: BuildNewPlayerState(gameCode)
      }));
    }
  }, []);

  return (
    <PlayerContext.Provider value={[state, setState]}>
      {props.children}
    </PlayerContext.Provider>
  );
};

export interface PlayerContextState {
  gameCode?: string;
  playerState?: PlayerStateFull;
  assessmentState?: PlayerAssessmentState;
  isLoaded?: boolean;
  isDirty: boolean;
}

export const BuildNewPlayerState = (gameCode: string): PlayerStateFull => {
  return {
    id: undefined,
    name: '',
    language: '',
    avatarImage: '',
    status: 'waiting',
    score: 0,
    coordinates: {
      latitude: 0,
      longitude: 0,
      accuracy: 0,
      altitude: 0,
      heading: 0
    },
    displayLanguageUrl: '',
    heading: 0,
    location: '',
    code: '',
    teamCode: '',
    inventory: [],
    titles: [],
    timers: [],
    zones: [],
    areas: [],
    tasks: [],
    latestCompletedTaskId: ''
  };
};

export { PlayerContext, PlayerStateProvider };
