import React, { useContext, useEffect, useState } from 'react';
import { TeamResponse } from '../../../../../types/responses/team-response';
import { GetAllAsync } from '../../../../../services/api';
import { PlayerContext } from '../../../../../contexts/player';
import { Col, Container, Row } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import { CardList } from '../../../../../components/card-list';
import { Card } from '../../../../../components/card';
import { ShowTeams } from '../../../../../utils/game-engine/team';
import { GameDocumentContext } from '../../../../../contexts/game-document';
import ColLoader from '../../../../../components/col-loader';
import { Input } from '@progress/kendo-react-inputs';
import MaterialIcon from '../../../../../components/material-icon';
import { GameContext } from '../../../../../contexts/game';
import { IsTeamLocked } from '../../../../../utils/game-state';
import { generateTitleById } from '../../../../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../../../../contexts/display-languages';

export interface TeamsListProps {
  onJoinTeam: (teamId: string) => void;
  onLeaveTeam: () => void;
  onViewTeam: (teamId: string) => void;
  onCreateTeam: () => void;
  canCreateTeams: boolean;
  canJoinLeaveTeam: boolean;
}

export const TeamsList = ({
  onJoinTeam,
  onLeaveTeam,
  onViewTeam,
  onCreateTeam,
  canCreateTeams = true,
  canJoinLeaveTeam = true
}: TeamsListProps) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [gameContext] = useContext(GameContext);
  const [playerState] = useContext(PlayerContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const [teams, setTeams] = React.useState<TeamResponse[]>();
  const newTeamNameRef = React.useRef<Input>();

  // Plumbing to allow the force refresh of the teams list.
  const [, updateState] = React.useState({});

  const showTeams = ShowTeams(gameDocument?.gameDocument!);

  const getTeams = () => {
    let isSubscribed = true;
    const fetchTeams = async () => {
      // get all teams
      let teamsResponse = await GetAllAsync<TeamResponse>(
        `games/${gameDocument.gameCode}/teams`
      );

      // sort alphabetically
      let tempData = teamsResponse.data.sort((a, b) =>
        a.name!.localeCompare(b.name!)
      );

      // move the current players team to the start...
      // TODO: Gotta be a better way...
      if (playerState.playerState?.teamCode) {
        let myTeamIndex = tempData.findIndex(
          (i) => i.teamCode === playerState.playerState?.teamCode
        );
        let myTeam = tempData.splice(myTeamIndex, 1);
        tempData = myTeam.concat(tempData);
      }

      if (isSubscribed) {
        setTeams(tempData);
      }
    };
    fetchTeams().catch(console.error);
    return () => {
      isSubscribed = false;
    };
  };

  // Load all the teams
  useEffect(() => {
    getTeams();
  }, []);

  const handleCreateTeam = () => {
    onCreateTeam();
  };

  return (
    <>
      <Container className={'drawer__body'}>
        <Row className={'mt-3 mb-4 align-items-center border-bottom pb-3'}>
          <Col>
            <h1 className={'m-0'}>
              {generateTitleById(
                '4be208b6-39fc-434d-bae7-e39f6d1ed332',
                gameDocument,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Teams'}
            </h1>
          </Col>
          <Col sm={12} md={'auto'}>
            <Button
              className={'k-button--icon'}
              themeColor={'primary'}
              fillMode={'flat'}
              onClick={() => {
                getTeams();
              }}
              title={'Refresh teams list'}>
              <MaterialIcon icon={'sync'} />
            </Button>
          </Col>
          {canCreateTeams && (
            <>
              <Col sm={12} md={'auto'}>
                <Button
                  className={'k-button--gradient'}
                  themeColor={'primary'}
                  onClick={handleCreateTeam}>
                  {generateTitleById(
                    '848ee4b0-e2ce-4974-a17c-d53bf10f6719',
                    gameDocument,
                    displayLanguageContext.displayLanguageSelected.resources!,
                    'game'
                  ) || 'Create new team'}
                </Button>
              </Col>
            </>
          )}
        </Row>

        <Row className={'mt-5 mb-5'}>
          {!teams && <ColLoader />}
          {showTeams && teams && (
            <>
              <Col xs={12}>
                <CardList>
                  {teams?.map((team, index) => (
                    <Card
                      key={index}
                      imageUrl={
                        team?.avatar === '' ||
                        team?.avatar === undefined ||
                        team?.avatar === null
                          ? " 'https://via.placeholder.com/200'"
                          : team?.avatar
                      }
                      title={
                        team.group?.name
                          ? team.group?.name! + ' - ' + team.name
                          : team.name!
                      }
                      subTitle={(team.totalPlayers ?? 0).toString()}
                      onClick={() => onViewTeam(team.teamCode!)}>
                      {canJoinLeaveTeam && (
                        <>
                          {team.teamCode ===
                          playerState.playerState?.teamCode ? (
                            <Button
                              className={'mt-1 k-button--gradient'}
                              themeColor={'primary'}
                              onClick={onLeaveTeam}
                              disabled={IsTeamLocked(
                                gameContext.gameState!,
                                team.teamCode
                              )}>
                              {generateTitleById(
                                '983f048a-ec82-47fd-9f7e-c59ab3526d5a',
                                gameDocument,
                                displayLanguageContext.displayLanguageSelected
                                  .resources!,
                                'game'
                              ) || 'Leave team'}
                            </Button>
                          ) : (
                            <Button
                              themeColor={'base'}
                              className={'mt-1'}
                              onClick={() => onJoinTeam(team.teamCode!)}
                              disabled={IsTeamLocked(
                                gameContext.gameState!,
                                team.teamCode
                              )}>
                              {generateTitleById(
                                '66883910-7d5d-4858-8ca0-f433072cc57e',
                                gameDocument,
                                displayLanguageContext.displayLanguageSelected
                                  .resources!,
                                'game'
                              ) || 'Join team'}
                            </Button>
                          )}
                        </>
                      )}
                    </Card>
                  ))}
                </CardList>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default TeamsList;
