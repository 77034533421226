import { useContext, useEffect, useState } from 'react';
import { GameContext } from '../../contexts/game';
import { GroupResponse } from '../../types/responses/group-response';
import { getGroupList, joinGroup } from '../../services/groups';
import { Button } from '@progress/kendo-react-buttons';
import Popup from '../../components/popup-window';
import { FacilitatorTeamChat } from './chat/team-chat';
import { TeamResponse } from '../../types/responses/team-response';
import { TeamState } from '../../types/state';
import { getTeamList, sendFacilitatorTeamChat } from '../../services/teams';
import { useAuth } from 'react-oidc-context';
import {
  NotificationContent,
  NotificationContext
} from '../../contexts/notification';
import { Input } from '@progress/kendo-react-inputs';
import { CreateGroupAndTeam } from '../../components/facilitator/create-group-and-team';
import ComboboxInput from '../../components/forms/combobox-input';
import { FacilitatorState } from '../../components/facilitator/facilitator-state';
import { AllGroupFacilitatorState } from '../../features/facilitator/group/all-group-state';

export const Groups = () => {
  const auth = useAuth();
  const userId = parseInt(auth.user?.profile.sub!);
  const userName = auth.user?.profile.name!;
  const [game] = useContext(GameContext);
  const [groups, setGroups] = useState<GroupResponse[]>([]);
  const [search, setSearch] = useState<string>('');
  const [showChat, setShowChat] = useState<boolean>(false);
  const [showChangeGroup, setShowChangeGroup] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<GroupResponse>();
  const [selectedTeam, setSelectedTeam] = useState<TeamState>();
  const [newGroup, setNewGroup] = useState<GroupResponse>();
  const [groupTeams, setGroupTeams] = useState<string[]>([]);
  const [notification, setNotification] = useContext(NotificationContext);
  const allGroupCode = 'all_groups';

  const teams =
    game?.gameState?.teams?.filter((x) => groupTeams?.includes(x.code!)) ?? [];

  const getGroupsAsync = async () => {
    let response = (await getGroupList(game.gameCode!)) as GroupResponse[];
    if (response.length > 0) {
      response.unshift({
        name: 'All Groups',
        code: allGroupCode
      });
    }
    setGroups(response ?? []);
  };

  /**
   * Handler on group search clicked
   * @param search
   */
  const onSearcHandler = (search: string) => {
    setSearch(search);
  };

  const onAddMessageAsync = async (message?: string) => {
    await sendFacilitatorTeamChat(
      game?.gameCode!,
      selectedTeam?.code,
      userId!,
      userName!,
      message!
    );
  };

  const onChangeGroup = () => {
    joinGroup(newGroup?.code!, selectedTeam?.code!, game?.gameCode!)
      .then((response) => {
        if (response) {
          responseGroupNotification(`Successfully changed the team's group`);
          setShowChangeGroup(false);
          getAllTeams();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getAllTeams = async () => {
    let response = (await getTeamList(game?.gameCode!)) as TeamResponse[];

    let teamIds = response
      ?.filter((x) => x.groupCode === selectedGroup?.code)
      .map((item) => item.teamCode);

    setGroupTeams((teamIds as string[]) ?? []);
  };

  const responseGroupNotification = (message: string) => {
    const responseTeamNotification: NotificationContent = {
      icon: 'notifications',
      isHide: false,
      message: (
        <span>
          <strong>{message}</strong>
        </span>
      ),
      color: 'k-button--gradient'
    };
    const content: NotificationContent[] = notification.content;
    content.push(responseTeamNotification);
    setNotification({ ...notification, content });
  };

  useEffect(() => {
    getGroupsAsync();
  }, []);

  useEffect(() => {
    getAllTeams();
  }, [selectedGroup]);

  useEffect(() => {
    if (groups?.length > 0) {
      if (!selectedGroup) {
        setSelectedGroup(groups[0]);
      }
    }
  }, [groups]);

  return (
    <>
      <div className={'d-flex justify-content-between mb-3'}>
        <div className={'w-25'}>
          <Input
            id={'search-text'}
            type={'text'}
            className={'form-control'}
            placeholder={'Search group...'}
            onChange={(e) => onSearcHandler((e.target.value as string) ?? '')}
          />
        </div>
        <CreateGroupAndTeam
          groupCodeOfTeam={selectedGroup?.code}
          onSubmitClick={() => {
            getGroupsAsync();
            getAllTeams();
          }}
        />
      </div>
      <div className={'d-flex flex-wrap'}>
        <div className={'d-flex flex-column group-menu'}>
          <div className={'border-list'}>
            <ul className="list-group facilitator-border-none">
              {groups
                ?.filter((x) =>
                  x?.name
                    ?.toLocaleLowerCase()
                    ?.includes(search?.toLocaleLowerCase())
                )
                ?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className="list-group-item cursor-pointer"
                      onClick={() => setSelectedGroup(item)}>
                      <div className={'d-flex justify-content-between'}>
                        <small
                          className={`${
                            item.code === selectedGroup?.code
                              ? 'fw-bold text-dark'
                              : 'text-dark'
                          }`}>
                          {item?.name}
                        </small>
                        <small className={'text-dark'}>
                          {item.code === allGroupCode
                            ? groups.length - 1
                            : game?.gameState?.teams?.filter(
                                (x) => x.groupCode === item?.code
                              )?.length}
                        </small>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        {selectedGroup && selectedGroup.code === allGroupCode && (
          <div className={'group-content'}>
            <div className={'ml-5'}>
              <button className={'tab-dark-button'}>
                <span className={'fw-bold'}>Details</span>
              </button>
              <hr className={'mt-0'} />
              <AllGroupFacilitatorState
                isDisableConfirm={
                  game.gameState?.status === 'finished' ||
                  !game.activityState?.hasGame
                }
              />
            </div>
          </div>
        )}
        {selectedGroup && selectedGroup.code !== allGroupCode && (
          <div className={'group-content'}>
            <div className={'ml-5'}>
              <button className={'tab-dark-button'}>
                <span className={'fw-bold'}>Details</span>
              </button>
              <hr className={'mt-0'} />

              <div className={'d-flex mt-3'}>
                <span className={'text-dark'}>Group name:</span>

                <span className={'text-dark fw-bold ml-3'}>
                  {selectedGroup?.name}
                </span>
              </div>
              <div>
                <div
                  className={
                    'box-rectangular d-flex flex-column justify-content-center align-items-center mt-3'
                  }>
                  <div>
                    <div
                      className={
                        'd-flex w-4 h-4 rounded-circle border border-3 border-success bg-white justify-content-center align-items-center'
                      }>
                      <span className={'text-success fw-bold'}>
                        {teams?.reduce((a, v) => (a = a + v.score! ?? 0), 0) ??
                          0}
                      </span>
                    </div>
                    <div
                      className={
                        'd-flex justify-content-center align-items-center mt-3'
                      }>
                      <span className={'text-dark fw-bold ml-3'}>Score</span>
                    </div>
                  </div>
                </div>
                <hr />

                {teams?.map((item, index) => (
                  <div
                    key={index}
                    className={
                      'd-flex w-full justify-content-center align-items-center'
                    }>
                    <div
                      className={
                        'd-flex box-default-color  align-items-center mt-2 w-full'
                      }>
                      <div className={'d-flex flex-grow-1 align-items-center'}>
                        <div
                          style={{
                            width: '60px'
                          }}
                          className={
                            'd-flex w-full justify-content-center align-items-center mr-2'
                          }>
                          {item?.avatarImage ? (
                            <img
                              style={{
                                width: '50px',
                                height: '50px',
                                objectFit: 'cover'
                              }}
                              src={item?.avatarImage}
                              className="w-3 h-3 rounded-circle"
                            />
                          ) : (
                            <span
                              style={{ fontSize: '60px' }}
                              className={
                                'material-symbols-outlined material-symbols-outlined--outlined text-dark'
                              }>
                              group_work
                            </span>
                          )}
                        </div>

                        <span className={'text-dark fw-bold ml-5'}>
                          {item?.name}
                        </span>
                      </div>

                      <span className={'text-primary fw-bold ml-3 mr-5'}>
                        {item?.score}
                      </span>
                    </div>

                    <Button
                      className={'facilitator-button-light ml-5'}
                      onClick={() => {
                        setSelectedTeam((prev) => item);
                        setShowChangeGroup(true);
                      }}>
                      Change group
                    </Button>
                    <Button
                      className={'facilitator-button-primary ml-3 mr-3'}
                      onClick={() => {
                        setSelectedTeam((prev) => item);
                        setShowChat(true);
                      }}>
                      Chat
                    </Button>
                  </div>
                ))}

                {teams?.length > 0 && (
                  <FacilitatorState
                    isGroup={true}
                    groupList={groups}
                    groupCode={selectedGroup?.code}
                    nameState={selectedGroup?.name}
                    isPlayer={false}
                    gameCode={game?.gameCode!}
                    playerOrTeamCode={selectedTeam?.code!}
                  />
                )}
              </div>
              {showChat && (
                <Popup
                  className={'box d-flex flex-column gap-3 w-50'}
                  handleClose={() => setShowChat(false)}>
                  <FacilitatorTeamChat
                    code={selectedTeam?.code}
                    item={selectedTeam as any}
                    onSendMessage={onAddMessageAsync}
                  />
                </Popup>
              )}
              {showChangeGroup && (
                <Popup
                  title={'Change group'}
                  handleClose={() => setShowChangeGroup(false)}>
                  <div className={'d-flex'}>
                    <ComboboxInput
                      style={{ zIndex: 1023 }}
                      data={groups}
                      textField={'name'}
                      dataItemKey={'name'}
                      className={'border w-20'}
                      onChange={(e) => {
                        setNewGroup(e.target.value);
                      }}
                      value={groups?.find((x) => x.code === newGroup)}
                    />
                    <Button
                      themeColor={'primary'}
                      className={
                        'ml-2 h-3 btn-icon-center facilitator-button-light'
                      }
                      onClick={onChangeGroup}
                      disabled={!newGroup}>
                      <span className={'material-symbols-outlined me-2'}>
                        group_add
                      </span>
                      Change group
                    </Button>
                  </div>
                </Popup>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
