import { useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { FacilitatorState } from '../../components/facilitator/facilitator-state';
import { GameContext } from '../../contexts/game';
import {
  getTeamList,
  getTeamStateAsync,
  joinTeam,
  leaveTeam
} from '../../services/teams';
import {
  getGroupList,
  getGroupSelectedAsync,
  joinGroup,
  leaveGroup
} from '../../services/groups';
import {
  GetTeamSelectedResponse,
  TeamResponse
} from '../../types/responses/team-response';
import { PlayerState, TeamStateFull } from '../../types/state';
import { GroupResponse } from '../../types/responses/group-response';
import {
  NotificationContent,
  NotificationContext
} from '../../contexts/notification';
import { TabButtonComponent } from '../../components/tabs/tab-button';
import { CreateGroupAndTeam } from '../../components/facilitator/create-group-and-team';
import { Input } from '@progress/kendo-react-inputs';
import { PlayerResponse } from '../../types/responses/player-response';
import {
  ChatFromFacilitator,
  PostChatFromFacilitator,
  getPlayerState,
  submitTaskAnswerScoringAsync
} from '../../services/players';
import { Button } from '@progress/kendo-react-buttons';
import Popup from '../../components/popup-window';
import { FacilitatorAndPlayerChat } from './chat/facilitator-player-chat';
import { useAuth } from 'react-oidc-context';
import { PlayerContextState } from '../../contexts/player';
import { TaskContentAnswerEntity, TaskEntity } from '../../types';
import { TaskCard } from '../../components/facilitator/task/task-card';
import { ManualScoring } from '../../types/manual-scoring';
import { AllTeamFacilitatorState } from '../../features/facilitator/team/all-team-state';

export const TeamsPage = () => {
  const [game] = useContext(GameContext);
  const [notification, setNotification] = useContext(NotificationContext);
  const [teams, setTeams] = useState<TeamResponse[]>([]);
  const [groups, setGroups] = useState<GroupResponse[]>([]);

  const [searchTeam, setSearchTeam] = useState<string>('');
  const [teamState, setTeamState] = useState<TeamStateFull>();
  const [selectedTeam, setSelectedTeam] = useState<GetTeamSelectedResponse>();
  const [selectedGroup, setSelectedGroup] = useState<GetTeamSelectedResponse>();
  const [selectedGroupCode, setSelectedGroupCode] = useState<string>('');
  const [playerStates, setPlayerStates] = useState<PlayerContextState[]>([]);

  const [selectedPlayer, setSelectedPlayer] = useState<PlayerState>();
  const [showPlayerChat, setShowPlayerChat] = useState<boolean>(false);
  const [showTeamList, setShowTeamList] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabs = ['Details', 'Members', 'Tasks'];
  const allTeamCode = 'all_teams';

  const auth = useAuth();
  const userId = parseInt(auth.user?.profile.sub!);
  const userName = auth.user?.profile.name!;

  const getAllTeams = async () => {
    let teamsResult = (await getTeamList(game?.gameCode!)) as TeamResponse[];
    if (teamsResult.length > 0) {
      teamsResult.unshift({
        name: 'All Teams',
        teamCode: allTeamCode
      });
    }
    setTeams(teamsResult);

    setSelectedTeam(teamsResult[0]);
  };

  const getSelectedTeamStateAsync = async () => {
    if (selectedTeam) {
      setSelectedGroup({});
      setSelectedGroupCode('');
      let teamStateResponse = await getTeamStateAsync(
        game?.gameCode!,
        selectedTeam.teamCode!
      );
      teamStateResponse.createdDateUtc = selectedTeam.createdDateUtc;
      setTeamState(teamStateResponse);
      await defaultSelectedGroupHandler(selectedTeam);
      getPlayersStateAsync(selectedTeam.teamCode!);
    }
  };

  const getAllGroups = async () => {
    let groupsResult = (await getGroupList(game?.gameCode!)) as GroupResponse[];
    setGroups(groupsResult);
  };

  const getPlayersStateAsync = async (teamCode: string) => {
    const playerStatePromises = game?.gameState?.players
      ?.filter((x) => x.teamCode === teamCode)
      .map(async (player) => {
        return await getPlayerState(game?.gameCode!, player.code!);
      });
    const playerStates = await Promise.all(playerStatePromises!);
    setPlayerStates(playerStates);
  };

  const updatePlayerStateTaskScore = (scoring: ManualScoring) => {
    if (playerStates) {
      setPlayerStates((prevItemPlayerStates) => {
        return prevItemPlayerStates.map((item) => {
          if (item.playerState?.code === scoring.playerCode) {
            // Find the task
            const tasks = item.playerState?.tasks;
            const taskIndex = tasks?.findIndex((x) => x.id === scoring.taskId);

            if (taskIndex === -1 || taskIndex === undefined) return item; // Task not found

            // Find the form index
            const taskContentFormAnswers =
              tasks![taskIndex]?.taskContentFormAnswers;
            const formIndex = taskContentFormAnswers?.findIndex(
              (x) => x.formId === scoring.formId
            );

            if (formIndex === -1 || formIndex === undefined) return item; // Form not found

            // Update the score immutably
            let newPlayerStates = { ...item };
            let newTasks = [...newPlayerStates.playerState!.tasks!];
            let newFormAnswers = [
              ...newTasks[taskIndex].taskContentFormAnswers!
            ];

            newFormAnswers[formIndex] = {
              ...newFormAnswers[formIndex],
              score: scoring.score
            };

            newTasks[taskIndex] = {
              ...newTasks[taskIndex],
              taskContentFormAnswers: newFormAnswers
            };

            newPlayerStates = {
              ...newPlayerStates,
              playerState: {
                ...newPlayerStates.playerState!,
                tasks: newTasks
              }
            };

            return newPlayerStates;
          } else {
            return item;
          }
        });
      });
    }
  };

  /**
   * Handler when team selected
   * @param team
   */
  const onClickHandler = async (team: TeamResponse) => {
    setSelectedTeam(team);
    setSelectedGroup({});
    setSelectedGroupCode('');

    try {
      let teamStateResponse = await getTeamStateAsync(
        game?.gameCode!,
        team?.teamCode!
      );
      teamStateResponse.createdDateUtc = team.createdDateUtc;
      await defaultSelectedGroupHandler(team);

      setTeamState(teamStateResponse);
    } catch (error) {
      console.error(error);
      setTeamState(undefined);
    }
  };

  const defaultSelectedGroupHandler = async (team: TeamResponse) => {
    if (
      team.groupCode &&
      team.groupCode !== '00000000-0000-0000-0000-000000000000'
    ) {
      let groupResult = await getGroupSelectedAsync(
        game?.gameCode!,
        team.groupCode ?? ''
      );
      if (groupResult) {
        setSelectedGroup(groupResult);
        setSelectedGroupCode(groupResult.code!);
      } else {
        setSelectedGroup({});
        setSelectedGroupCode('');
      }
    } else {
      setSelectedGroup({});
      setSelectedGroupCode('');
    }
  };

  const handleChangeGroup = (group: GroupResponse) => {
    if (group) {
      setSelectedGroupCode(group ? group.code! : '');
    } else {
      setSelectedGroupCode('');
    }
  };

  /**
   * Handler on team search clicked
   * @param search
   */
  const onSearcHandler = (search: string) => {
    setSearchTeam(search);
  };

  const onLockHandler = (teamCode: string, isLocked: boolean) => {
    let newTeams = teams;

    let index = teams?.findIndex((x) => x.teamCode === teamCode);

    if (index > -1) {
      newTeams[index].isLocked = isLocked;
      setTeams(newTeams);
    }
  };

  const onDisabledChatHandler = (teamCode: string, isDisabledChat: boolean) => {
    let newTeams = teams;

    let index = teams?.findIndex((x) => x.teamCode === teamCode);
    if (index > -1) {
      newTeams[index].isChatDisabled = isDisabledChat;

      setTeams(newTeams);
    }
  };

  const handleAddTeamToGroup = () => {
    joinGroup(selectedGroupCode!, selectedTeam?.teamCode!, game?.gameCode!)
      .then((response) => {
        if (response) {
          responseGroupNotification('Success add team to a group ');
          // getAllTeams();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleRemoveTeamFromGroup = async () => {
    await leaveGroup(
      selectedGroup?.code!,
      selectedTeam?.teamCode!,
      game?.gameCode!
    )
      .then((response) => {
        responseGroupNotification('Team has been removed from group ');
        // getAllTeams();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const responseGroupNotification = (message: string) => {
    const responseTeamNotification: NotificationContent = {
      icon: 'notifications',
      isHide: false,
      message: (
        <span>
          <strong>{message}</strong>
        </span>
      ),
      color: 'k-button--gradient'
    };
    const content: NotificationContent[] = notification.content;
    content.push(responseTeamNotification);
    setNotification({ ...notification, content });
  };

  const changePlayerTeamHandler = async (
    leaveTeamCode: string,
    joinTeamCode: string,
    playerCode: string
  ) => {
    try {
      setIsLoading(true);
      await leaveTeam(leaveTeamCode, playerCode, game.gameCode!);
      await joinTeam(joinTeamCode, playerCode, game.gameCode!);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setShowTeamList(false);
    }
  };

  const RemovePlayerOnTeamHandler = async (
    leaveTeamCode: string,
    playerCode: string
  ) => {
    try {
      setIsLoading(true);
      await leaveTeam(leaveTeamCode, playerCode, game.gameCode!);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSendMessageHandler = async (message: string) => {
    let payload: ChatFromFacilitator = {
      facilitatorName: userName,
      facilitatorUserProfileId: userId,
      toPlayerCode: (selectedPlayer as PlayerResponse).code!,
      message: message
    };

    await PostChatFromFacilitator(game?.gameCode!, payload);
  };

  const submitScoring = async (scoring: any) => {
    try {
      await submitTaskAnswerScoringAsync(scoring);
    } catch (error) {
      console.error('Failed submit scoring');
    } finally {
      updatePlayerStateTaskScore(scoring);
    }
  };

  const renderTeamMembers = () => {
    const members = playerStates?.filter(
      (x) => x.playerState?.teamCode === selectedTeam?.teamCode
    );
    return (
      <div>
        <div className={'mb-2'}>
          Team name: <strong>{selectedTeam?.name}</strong>
        </div>
        <div>
          {members &&
            members.length > 0 &&
            members.map((item, index) => (
              <div
                className={
                  'd-flex justify-content-between align-items-center mb-2'
                }
                key={index}>
                <div
                  className={
                    'd-flex justify-content-between w-100 align-items-center p-2 box-default-color'
                  }>
                  <div className={'d-flex align-items-center'}>
                    <img
                      width={30}
                      height={30}
                      src={item.playerState?.avatarImage}
                      className={'rounded-circle'}
                      alt={item.playerState?.name}
                    />
                    <div className={'ml-2'}>
                      <strong>{item.playerState?.name}</strong>
                    </div>
                  </div>
                  <div className={'text-primary justify-self-end'}>
                    <strong>{item.playerState?.score}</strong>
                  </div>
                </div>
                <div className={'d-flex align-items-center ml-2'}>
                  <Button
                    className={'facilitator-button-light'}
                    onClick={() => {
                      setSelectedPlayer(item.playerState);
                      setShowTeamList(true);
                    }}>
                    Change team
                  </Button>
                  <Button
                    className={'facilitator-button-light mx-2'}
                    onClick={() => {
                      if (selectedTeam && selectedTeam.teamCode) {
                        setSelectedPlayer(item.playerState);
                        RemovePlayerOnTeamHandler(
                          selectedTeam?.teamCode,
                          item.playerState!.code!
                        );
                      }
                    }}>
                    Remove player
                  </Button>
                  <Button
                    themeColor={'primary'}
                    onClick={() => {
                      setSelectedPlayer(item.playerState);
                      setShowPlayerChat(true);
                    }}>
                    Chat
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const reloadHandler = () => {
    try {
      setIsLoading(true);
      getPlayersStateAsync(selectedTeam!.teamCode!);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllGroups();
    getAllTeams();
  }, []);

  useEffect(() => {
    getSelectedTeamStateAsync();
  }, [selectedTeam]);

  return (
    <>
      {isLoading && (
        <div
          className={
            'd-flex flex-column align-items-center justify-content-center h-100 w-100 gap-2'
          }>
          <span className={'loader'}></span>
          <label className={'text-gray'}>Loading...</label>
        </div>
      )}
      {teams.length === 0 && <h3>No teams found</h3>}
      <div className={'d-flex justify-content-between mb-3'}>
        <div className={'w-25'}>
          <Input
            id={'search-text'}
            type={'text'}
            className={'form-control'}
            placeholder={'Search team...'}
            onChange={(e) => onSearcHandler((e.target.value as string) ?? '')}
          />
        </div>
        <CreateGroupAndTeam
          groupCodeOfTeam={selectedGroup?.code}
          onSubmitClick={() => {
            getAllTeams();
          }}
        />
      </div>
      <div className={'d-flex flex-wrap'}>
        <div className={'d-flex flex-column group-menu'}>
          <div className={'border-list'}>
            <ul className="list-group facilitator-border-none">
              {teams
                .filter((x) =>
                  !searchTeam || searchTeam === ''
                    ? x
                    : x.name?.toLowerCase()?.includes(searchTeam?.toLowerCase())
                )
                .map((item) => {
                  return (
                    <li
                      className="list-group-item cursor-pointer"
                      onClick={() => onClickHandler(item)}>
                      <div className={'d-flex justify-content-between'}>
                        <small
                          className={`${
                            item.teamCode === selectedTeam?.teamCode
                              ? 'fw-bold text-dark'
                              : 'text-dark'
                          }`}>
                          {item?.name}
                        </small>
                        <small className={'text-dark'}>
                          {item.teamCode === allTeamCode
                            ? teams.length - 1
                            : game?.gameState?.players?.filter(
                                (x) => x.teamCode === item?.teamCode
                              )?.length}
                        </small>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className={'team-content'}>
          {selectedTeam && selectedTeam.teamCode === allTeamCode && (
            <Row className={'ml-3'}>
              <Row className={'container-tab-component'}>
                <TabButtonComponent
                  thisTabIndex={-1}
                  activeTab={-1}
                  title={'Details'}
                  onSetActiveTab={(tabIndex) => {}}
                />
              </Row>
              <Row>
                <AllTeamFacilitatorState
                  isDisableConfirm={
                    game.gameState?.status === 'finished' ||
                    !game.activityState?.hasGame
                  }
                />
              </Row>
            </Row>
          )}
          {selectedTeam && selectedTeam.teamCode !== allTeamCode && (
            <Row className={'ml-3'}>
              <Row className={'container-tab-component'}>
                {tabs.map((item, index) => {
                  return (
                    <TabButtonComponent
                      thisTabIndex={index}
                      activeTab={activeTab}
                      title={item}
                      onSetActiveTab={(tabIndex) => {
                        setActiveTab(tabIndex);
                      }}
                    />
                  );
                })}
                <Button
                  onClick={() => reloadHandler()}
                  themeColor={'info'}
                  className={
                    'me-1 col-3 p-4 d-flex align-items-center justify-items-center k-button--tab'
                  }
                  fillMode={'flat'}>
                  <span className="material-symbols-outlined">sync</span>
                </Button>
                <hr className={'mt-0'} />
              </Row>
              <Row>
                {activeTab === 0 && (
                  <FacilitatorState
                    isGroup={false}
                    isPlayer={false}
                    gameCode={game?.gameCode!}
                    playerOrTeamCode={selectedTeam?.teamCode!}
                    selectedTeam={selectedTeam}
                    state={teamState}
                    groupList={groups}
                    nameState={selectedTeam?.name}
                    isLocked={teamState?.isLocked!}
                    isDisabledChat={teamState?.isChatDisabled!}
                    onLockTeam={onLockHandler}
                    onDisabledChatTeam={onDisabledChatHandler}
                    groupCode={selectedGroupCode}
                    handleChangeGroup={handleChangeGroup}
                    selectedGroup={selectedGroup}
                    handleAddTeamToGroup={handleAddTeamToGroup}
                    handleRemoveTeamFromGroup={handleRemoveTeamFromGroup}
                    isDisableConfirm={
                      game.gameState?.status === 'finished' ||
                      !game.activityState?.hasGame
                    }
                  />
                )}
                {activeTab === 1 && renderTeamMembers()}
                {activeTab === 2 && (
                  <Row>
                    {playerStates
                      ?.filter(
                        (x) => x.playerState?.teamCode === selectedTeam.teamCode
                      )
                      .map((playerState, outerIndex) => {
                        return playerState?.playerState?.tasks?.map(
                          (task: TaskEntity, taskIndex: number) => {
                            return task?.taskContentFormAnswers?.map(
                              (
                                taskContentAnswer: TaskContentAnswerEntity,
                                taskContentAnswerIndex: number
                              ) => {
                                return (
                                  <TaskCard
                                    key={`task-card-${taskContentAnswerIndex}`}
                                    taskContentAnswer={taskContentAnswer}
                                    index={taskContentAnswerIndex}
                                    selectedTask={task!}
                                    playerName={playerState?.playerState?.name}
                                    playerCode={playerState?.playerState?.code}
                                    isEdit={false}
                                    onSubmitScore={submitScoring}
                                  />
                                );
                              }
                            );
                          }
                        );
                      })}
                  </Row>
                )}
              </Row>
            </Row>
          )}
        </div>
        {showPlayerChat && selectedPlayer && (
          <Popup
            className={'box d-flex flex-column gap-3 w-50'}
            handleClose={() => setShowPlayerChat(false)}>
            <FacilitatorAndPlayerChat
              item={selectedPlayer as PlayerResponse}
              onSendMessage={(message) => onSendMessageHandler(message)}
            />
          </Popup>
        )}
        {showTeamList && (
          <Popup
            handleClose={() => {
              setSelectedPlayer(undefined);
              setShowTeamList(false);
            }}
            title={'Team list'}
            className={
              'box box-small assessment-export d-flex flex-column position-relative'
            }>
            <ul className={'p-0'}>
              {selectedPlayer &&
                teams &&
                teams
                  .filter((item) => item.teamCode !== selectedTeam?.teamCode)
                  .map((item, index) => {
                    return (
                      <li className={'k-listview-item row p-2 border-bottom'}>
                        <div
                          className={
                            'd-flex justify-content-between align-items-center'
                          }>
                          <div>{item.name}</div>
                          <Button
                            onClick={() => {
                              if (
                                selectedPlayer &&
                                selectedPlayer.code &&
                                selectedTeam &&
                                selectedTeam.teamCode
                              ) {
                                changePlayerTeamHandler(
                                  selectedTeam?.teamCode,
                                  item.teamCode!,
                                  selectedPlayer.code
                                );
                              }
                            }}
                            themeColor={'success'}>
                            Select team
                          </Button>
                        </div>
                      </li>
                    );
                  })}
            </ul>
          </Popup>
        )}
      </div>
    </>
  );
};
