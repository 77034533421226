import { ComponentPropsWithRef } from 'react';

export interface AchievementDialogProps extends ComponentPropsWithRef<'div'> {
  imageUrl?: string;
  onMaskClick?: () => void;
  priorityQueueNumber?: number;
}

export const AchievementDialog = ({
  onMaskClick = () => {},
  imageUrl,
  ...props
}: AchievementDialogProps) => {
  const stopOnClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const zIndex = !props.priorityQueueNumber
    ? 1020
    : 1020 * props.priorityQueueNumber!;

  return (
    <div
      className={'achievement-dialog__mask'}
      style={{ zIndex: zIndex }}
      onClick={onMaskClick}>
      <div
        {...props}
        className={`achievement-dialog ${props.className ?? ''}`.trim()}>
        <div
          className={'achievement-dialog__body overflow-auto'}
          onClick={stopOnClick}>
          {props.children}
        </div>
        {imageUrl && (
          <div className={'achievement-dialog__image'} onClick={stopOnClick}>
            <img src={imageUrl} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AchievementDialog;
