import redaxios from 'redaxios';
import { getUrl, HttpStatus } from './http-client';
import { API_URL, API_V2_URL } from '../constants';
import { GetAllAsync, GetAsync, PatchAsync, PostAsync } from '../services/api';
import { PlayerValidate } from '../types/player-validate';
import {
  PlayerResponse,
  UploadResponse
} from '../types/responses/player-response';
import { GetListAvatar } from './avatar';
import { ManualScoring } from '../types/manual-scoring';
import { AlghoritmTask } from '../types/alghoritm-task';
import { PlayerContextState } from '../contexts/player';
import { PlayerContactResponse } from '../types/responses/player-contacts-reponse';

export interface Response {
  isError: boolean;
  statusCode: HttpStatus;
  message: string;
  result: any;
}

export interface AvatarList {
  id: number;
  item: string;
}

export interface ChatToFacilitator {
  fromPlayerCode: string;
  message: string;
}

export interface ChatFromFacilitator {
  facilitatorUserProfileId: number;
  facilitatorName: string;
  toPlayerCode: string;
  message: string;
}

export interface PlayerChatHistory {
  id: number;
  playerCode: string;
  playerName: string;
  playerAvatar: string;
  sentDateUtc: string;
  message: string;
}

export interface ChatBetweenPlayer {
  fromPlayerCode: string;
  toPlayerCode: string;
  message: string;
}

export async function joinPlayer(
  gameCode?: string,
  playerName?: string,
  playerAvatar?: string
) {
  let request = {
    name: playerName,
    avatar: playerAvatar
  };
  let response = await PostAsync<PlayerResponse>(
    `games/${gameCode}/players`,
    request
  );
  return response;
}

export async function joinAssessmentPlayer(
  gameCode?: string,
  playerName?: string,
  playerAvatar?: string,
  teamCode?: string
) {
  let request = {
    name: playerName,
    avatar: playerAvatar,
    teamCode: teamCode
  };
  let response = await PostAsync<PlayerResponse>(
    `assessments/${gameCode}/players`,
    request
  );
  return response;
}

export async function getPlayerId(playerCode: string): Promise<Response> {
  try {
    const url = getUrl(`${API_URL.player}/${playerCode}`);
    const response = await redaxios.get(url);
    return response?.data?.result;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    const err = error as any;
    const errorRes: Response = {
      isError: true,
      statusCode: err?.status,
      message: err?.data,
      result: err?.data
    };
    return errorRes;
  }
}

export async function updatePlayer(
  playerCode?: string,
  gameCode?: string,
  playerName?: string,
  playerAvatar?: string
) {
  let request = {
    name: playerName,
    avatar: playerAvatar
  };
  let response = await PatchAsync<PlayerResponse>(
    `games/${gameCode}/players/${playerCode}`,
    request
  );
  return response;
}

export async function getAvatars(gameCode: string) {
  let response = await GetListAvatar(`games/${gameCode}/players/avatars`);
  return response;
}

export async function getPlayerState(gameCode: string, playerCode: string) {
  let response = await GetAsync<PlayerContextState>(
    `games/${gameCode}/players/${playerCode}/states`
  );
  return response;
}

export function validatePlayer(gameCode: string, playerName: string) {
  const request = {
    name: playerName
  };
  const response = PostAsync<PlayerValidate>(
    `games/${gameCode}/players/validate`,
    request
  )
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return response;
}

export async function uploadAvatar(file: File): Promise<Response> {
  try {
    let formData = new FormData();
    formData.append('avatarImage', file as Blob);

    const url = getUrl(API_URL.playerAvatar);
    const response = await redaxios.post(url, formData);
    const res: Response = {
      isError: response?.data?.isError,
      statusCode: response?.data?.statusCode,
      message: response?.data?.message,
      result: response?.data?.result
    };
    return res;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    const err = error as any;
    const errorRes: Response = {
      isError: true,
      statusCode: err?.status,
      message: err?.data,
      result: err?.data
    };
    return errorRes;
  }
}

/**
 *
 * @param playerCode // playerCode you want to send to signalR the playerState
 */
export const GetPlayerHub = async (playerCode: string) => {
  try {
    const url = getUrl(`${API_URL.hubs}/playerstate/${playerCode}`);
    const response = await redaxios.post(url);
    return response?.data?.result;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

export const GetManualScoringAnswers = async (gameCode: string) => {
  const response = await GetAllAsync<ManualScoring>(
    `games/${gameCode}/players/answer`
  );

  return response?.data;
};

export async function addManualScoringAsync(
  gameCode?: string,
  playerCode?: string,
  taskId?: string,
  formId?: string,
  answer?: string
) {
  let request = {
    gameCode: gameCode,
    playerCode: playerCode,
    taskId: taskId,
    formId: formId,
    answer: answer
  };

  const url = getUrl(
    `${API_URL.gamesV2}/${gameCode}/players/${playerCode}/answer`
  );
  const response = await redaxios.post(url, request as any);
  return response;
}

export async function submitScoringAsync(scoring: ManualScoring) {
  let response = await PostAsync<ManualScoring>(
    `games/${scoring?.gameCode}/players/${scoring?.playerCode}/submit-score`,
    scoring as any
  );
  return response;
}
export async function submitTaskAnswerScoringAsync(scoring: ManualScoring) {
  const listIds = [scoring.taskId!, scoring.formId];
  let response = await PostAsync<ManualScoring>(
    `games/${scoring?.gameCode}/players/${scoring?.playerCode}/task-score?score=${scoring.score}`,
    listIds as any
  );
  return response;
}

export async function getPlayers(code: string) {
  try {
    const response = await GetAllAsync<PlayerResponse>(`games/${code}/players`);
    return response?.data;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    const err = error as any;
    const errorRes: Response = {
      isError: true,
      statusCode: err?.status,
      message: err?.data,
      result: err?.data
    };
    return errorRes;
  }
}

export async function getPlayersChatHistoryAsync(code: string) {
  try {
    const response = await GetAllAsync<PlayerContactResponse>(
      `games/${code}/players/chats/contacts`
    );
    return response;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    const err = error as any;
    const errorRes: Response = {
      isError: true,
      statusCode: err?.status,
      message: err?.data,
      result: err?.data
    };
    return errorRes;
  }
}

export function navigateToWorldMap(gameCode: string, playerCode: string) {
  const response = PostAsync(
    `games/${gameCode}/players/${playerCode}/locate?zoneId=world-map`,
    {}
  )
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return response;
}

export function navigateToZone(
  gameCode: string,
  playerCode: string,
  zoneId: string
) {
  const response = PostAsync(
    `games/${gameCode}/players/${playerCode}/locate?zoneId=${zoneId}`,
    {}
  )
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return response;
}

export function showTask(
  gameCode: string,
  playerCode: string,
  taskId: string,
  visible: boolean
) {
  const response = PostAsync(
    `games/${gameCode}/players/${playerCode}/tasks/${taskId}/?visible=${visible}`,
    {}
  )
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return response;
}

export function openTask(gameCode: string, playerCode: string, taskId: string) {
  const response = PostAsync(
    `games/${gameCode}/players/${playerCode}/tasks/${taskId}`,
    {}
  )
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return response;
}

export function showArea(
  gameCode: string,
  playerCode: string,
  areaId: string,
  visible: boolean
) {
  const response = PostAsync(
    `games/${gameCode}/players/${playerCode}/areas/${areaId}/?visible=${visible}`,
    {}
  )
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return response;
}

export function startTimer(
  gameCode: string,
  playerCode: string,
  timerId: string,
  start: boolean
) {
  const response = PostAsync(
    `games/${gameCode}/players/${playerCode}/timers/${timerId}/start/?start=${start}`,
    {}
  )
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return response;
}

export function pauseTimer(
  gameCode: string,
  playerCode: string,
  timerId: string,
  pause: boolean
) {
  const response = PostAsync(
    `games/${gameCode}/players/${playerCode}/timers/${timerId}/pause/?pause=${pause}`,
    {}
  )
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return response;
}

export function giveItem(
  gameCode: string,
  playerCode: string,
  itemId: string,
  quantity: number,
  add: boolean
) {
  const response = PostAsync(
    `games/${gameCode}/players/${playerCode}/items/${itemId}/?quantity=${quantity}&add=${add}`,
    {}
  )
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return response;
}

export function giveScore(
  gameCode: string,
  playerCode: string,
  score: number,
  add: boolean
) {
  const response = PostAsync(
    `games/${gameCode}/players/${playerCode}/score/?score=${score}&add=${add}`,
    {}
  )
    .then(() => {
      return true;
    })
    .catch((err) => {
      return false;
    });

  return response;
}

export function giveTaskScore(
  gameCode: string,
  playerCode: string,
  score: number
) {
  const response = PostAsync(
    `games/${gameCode}/players/${playerCode}/score/?score=${score}`,
    {}
  )
    .then(() => {
      return true;
    })
    .catch((err) => {
      return false;
    });
  return response;
}

export function giveTitle(
  gameCode: string,
  playerCode: string,
  titleId: string,
  add: boolean
) {
  const response = PostAsync(
    `games/${gameCode}/players/${playerCode}/titles/${titleId}/?add=${add}`,
    {}
  )
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return response;
}

export async function distributeTaskAsync(
  gameCode: string,
  playerCode: string,
  tasks: AlghoritmTask
) {
  let response = await PostAsync<string[]>(
    `games/${gameCode}/players/${playerCode}/tasks/distribute`,
    tasks as never
  );
  return response;
}

export async function distributeTask(
  gameCode: string,
  playerCode: string,
  tasks: AlghoritmTask
) {
  const url = getUrl(
    `${API_URL.gamesV2}/${gameCode}/players/${playerCode}/tasks/distribute`
  );
  const response = await redaxios.post(url, tasks as string[]);
  return response;
}

/**
 * Upload File from blob to azure storage
 * @param url - blog url of file upload
 * @param gameCode -gameCode
 * @returns the resource object
 */
export const postResourceAsync = async (
  gameCode: string,
  file: File | Blob
) => {
  try {
    let formData = new FormData();
    formData.append('file', file as Blob);
    const url = getUrl(`${API_V2_URL.games}${gameCode}/players/upload `);
    const response = await redaxios.post(url, formData);
    const res: UploadResponse = {
      name: response.data.name,
      extension: response.data.extension,
      contextSize: response.data.contextSize,
      mimeType: response.data.mimeType,
      url: response.data.url
    };
    return res;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    const err = error as any;
    const errorRes: Response = {
      isError: true,
      statusCode: err?.status,
      message: err?.data,
      result: err?.data
    };
    return errorRes;
  }
};

export function KickPlayerAsync(gameCode: string, playerCode: string) {
  try {
    const response = PostAsync(
      `games/${gameCode}/players/${playerCode}/kick`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export const KickAssessmentPlayerAsync = (
  assessmentCode: string,
  playerCode: string
) => {
  try {
    PostAsync(`assessments/${assessmentCode}/kick/${playerCode}`, {});
  } catch (error) {
    throw error;
  }
};

export const PatchPlayerAssessment = async (
  assessmentCode: string,
  playerCode: string,
  teamCode: string
) => {
  try {
    let request = {
      teamCode: teamCode
    };
    let response = await PatchAsync<PlayerResponse>(
      `assessments/${assessmentCode}/players/${playerCode}`,
      request
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const GetPlayerAssessment = async (
  assessmentCode: string,
  playerCode: string
) => {
  try {
    let response = await GetAsync<PlayerResponse>(
      `assessments/${assessmentCode}/players/${playerCode}/states`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Send chat from player to facilitator
 */
export const PostChatFacilitator = async (
  gameCode: string,
  message: ChatToFacilitator
) => {
  try {
    const url = getUrl(
      `${API_URL.gamesV2}/${gameCode}/players/chats/to-facilitator`
    );
    const response = await redaxios.post(url, message);
    return response?.data?.result;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Send chat from facilitator to player
 */
export const PostChatFromFacilitator = async (
  gameCode: string,
  message: ChatFromFacilitator
) => {
  try {
    const url = getUrl(
      `${API_URL.gamesV2}/${gameCode}/players/chats/facilitator/to-player`
    );
    const response = await redaxios.post(url, message);
    return response?.data?.result;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Get player & facilitator chat history
 * @param code // Event code
 */
export const GetPlayerFacilitatorChatHistoryAsync = async (
  code: string,
  playerCode: string
) => {
  try {
    const url = getUrl(
      `${API_URL.gamesV2}/${code}/players/chats/facilitator/${playerCode}`
    );
    const response = await redaxios.get<PlayerChatHistory[]>(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Get list chat with another players
 */
export const GetChatWithPlayersAsync = async (
  gameCode: string,
  playerCode: string
) => {
  try {
    const url = getUrl(
      `${API_URL.gamesV2}/${gameCode}/players/chats/${playerCode}/contact`
    );
    const response = await redaxios.get<PlayerResponse[]>(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Get chat history between players
 * @param gameCode // Game code
 */
export const GetChatHistoryBetweeenPlayersAsync = async (
  gameCode: string,
  playerCode1: string,
  playerCode2: string
) => {
  try {
    const url = getUrl(
      `${API_URL.gamesV2}/${gameCode}/players/chats/${playerCode1}/${playerCode2}`
    );
    const response = await redaxios.get<PlayerChatHistory[]>(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Send chat between player
 */
export const PostChatBetweenPlayer = async (
  gameCode: string,
  message: ChatBetweenPlayer
) => {
  try {
    const url = getUrl(`${API_URL.gamesV2}/${gameCode}/players/chats`);
    const response = await redaxios.post(url, message);
    return response?.data?.result;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};
