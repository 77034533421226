import { Col, Row } from 'react-bootstrap';
import { PlayerStateFull } from '../../../types/state';
import { useContext, useEffect, useState } from 'react';
import { GameDocumentContext } from '../../../contexts/game-document';
import { GetItemTitle } from '../../../utils/game-document/assets/items';
import { GetTitle } from '../../../utils/game-document/assets/titles';
import { getLocation } from '../../../utils/game-document/location';
import { getDateDuration, getFormattedDateString } from '../../../utils/date';
import { GameContext } from '../../../contexts/game';
import { GroupResponse } from '../../../types/responses/group-response';
import Popup from '../../popup-window';
import { TabButtonComponent } from '../../tabs/tab-button';
import {
  GridColumn as Column,
  Grid,
  GridCellProps,
  GridColumnMenuFilter,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { DefaultGridSettings } from '../../../constants/grid-settings';
import { TeamResponse } from '../../../types/responses/team-response';

const initialDataState: State = {
  sort: [{ field: 'name' }],
  ...DefaultGridSettings.initialDataState
};

export interface PlayerStateDashboardProps {
  state?: PlayerStateFull;
  team?: TeamResponse;
  group?: GroupResponse;
}

export const PlayerStateDashboard = ({
  ...props
}: PlayerStateDashboardProps) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [game] = useContext(GameContext);
  const [showInventories, setShowInventories] = useState(false);
  const [showTitles, setShowTitles] = useState(false);
  const [inventories, setInventories] = useState([]);
  const [titles, setTitles] = useState([]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabs = ['Titles', 'Inventories'];
  const [dataState, setDataState] = useState<State>(initialDataState);
  const generateInventories = () => {
    return (
      props.state?.inventory?.map((item) => {
        const data = {
          name: GetItemTitle(gameDocument.gameDocument!, item.id, '') ?? '',
          quantity: item.quantity
        };
        return data;
      }) ?? []
    );
  };

  const generateTitles = () => {
    return props.state?.titles?.map((item) => {
      const data = {
        name: GetTitle(gameDocument.gameDocument!, item, '') ?? '',
        assignedTo: props.state?.name
      };
      return data;
    });
  };

  useEffect(() => {
    const dataInventories = generateInventories();
    const dataTitles = generateTitles();
    setInventories(dataInventories as []);
    setTitles(dataTitles as []);
  }, [props.state]);

  return (
    <div>
      <Row>
        <Col className={'col-3'}>
          <div className={'d-flex'}>
            <div className={'d-flex justify-content-center w-full'}>
              <div
                className={'d-flex justify-content-between align-items-center'}>
                {props?.state?.avatarImage && (
                  <img
                    width={150}
                    height={150}
                    src={props?.state?.avatarImage}
                    className={'rounded-circle'}
                    alt={props?.state?.name}
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <Row>
            <h1 className={'text-primary mt-2'}>
              <b>{props?.state?.name}</b>
            </h1>
          </Row>
          <Row>
            <Col>
              <ul className="list-unstyled">
                <li>
                  <span>Team: </span>
                  <strong>{props?.team?.name}</strong>
                </li>
                <li>
                  <span>Group:</span>
                  <strong> {props?.team?.group?.name ?? ''}</strong>
                </li>
              </ul>
            </Col>
            <Col>
              <ul className="list-unstyled">
                <li>
                  <span>Status: </span>
                  <strong>{props?.state?.status} </strong>
                </li>
                <li>
                  <span>Location: </span>
                  <strong>
                    {gameDocument?.gameDocument
                      ? getLocation(
                          gameDocument?.gameDocument!,
                          props?.state?.location ?? ''
                        )
                      : ''}
                  </strong>
                </li>
              </ul>
            </Col>
            <Col>
              <ul className="list-unstyled">
                <li>
                  <span>Joined date:</span>{' '}
                  <strong>
                    {props?.state?.joinedDate &&
                      getFormattedDateString(
                        new Date(props?.state?.joinedDate)
                      )}
                  </strong>
                </li>
                <li>
                  <span>Duration:</span>{' '}
                  <strong>
                    {props?.state?.joinedDate &&
                      getDateDuration(
                        new Date(props?.state?.joinedDate),
                        !game?.gameState?.finishedUtc
                          ? new Date()
                          : new Date(game?.gameState?.finishedUtc)
                      )}
                  </strong>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
      {game.activityState?.hasGame && game.gameState?.status !== 'finished' && (
        <div className={'d-flex justify-content-start gap-4 mt-4 w-100'}>
          <div
            className={
              'box-rectangular d-flex flex-column justify-content-center align-items-center'
            }>
            <div className={'d-flex flex-column align-items-center'}>
              <div
                className={
                  'd-flex w-4 h-4 rounded-circle border border-3 border-success bg-white justify-content-center align-items-center'
                }>
                <span className={'text-success fw-bold'}>
                  {props?.state?.score ?? 0}
                </span>
              </div>
              <div
                className={
                  'd-flex justify-content-center align-items-center mt-3'
                }>
                <span className={'text-dark fw-bold'}>Score</span>
              </div>
            </div>
          </div>
          <div
            className={
              'box-rectangular cursor-pointer d-flex flex-column justify-content-center align-items-center'
            }
            onClick={() => setShowInventories(true)}>
            <div className={'d-flex flex-column align-items-center'}>
              <div
                className={
                  'd-flex w-4 h-4 rounded-circle border border-3 border-success bg-white justify-content-center align-items-center'
                }>
                <span className={'text-success fw-bold'}>
                  {props?.state?.inventory?.length ?? 0}
                </span>
              </div>
              <div
                className={
                  'd-flex justify-content-center align-items-center mt-3'
                }>
                <span className={'text-dark fw-bold'}>Inventories</span>
              </div>
            </div>
          </div>
          <div
            className={
              'box-rectangular cursor-pointer d-flex flex-column justify-content-center align-items-center p-4 fw-bold bg-card-blue'
            }
            onClick={() => setShowTitles(true)}>
            <div className={'d-flex flex-column align-items-center'}>
              <div
                className={
                  'd-flex w-4 h-4 rounded-circle border border-3 border-success bg-white justify-content-center align-items-center'
                }>
                <span className={'text-success fw-bold'}>
                  {props?.state?.titles?.length ?? 0}
                </span>
              </div>
              <div
                className={
                  'd-flex justify-content-center align-items-center mt-3'
                }>
                <span className={'text-dark fw-bold'}>Titles</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {(showTitles || showInventories) && (
        <Popup
          handleClose={() => {
            setShowTitles(false);
            setShowInventories(false);
          }}
          className={'box d-flex flex-column w-80 h-80'}>
          <div className={'d-flex flex-column w-full h-100'}>
            <Row>
              <h1 className={'text-primary mt-2'}>
                <b>{props?.state?.name}</b>
              </h1>
            </Row>
            <Row className={'container-tab-component mb-2'}>
              {tabs.map((item, index) => {
                return (
                  <TabButtonComponent
                    thisTabIndex={index}
                    activeTab={showTitles ? 0 : 1}
                    title={item}
                    onSetActiveTab={(tabIndex) => {
                      if (tabIndex > 0) {
                        setShowInventories(true);
                        setShowTitles(false);
                      } else {
                        setShowInventories(false);
                        setShowTitles(true);
                      }
                    }}
                  />
                );
              })}
              <hr className="mt-0" />
            </Row>
            <Row>
              {showTitles && (
                <Grid
                  id={'grid-titles'}
                  pageable={DefaultGridSettings.pagerSettings}
                  sortable={true}
                  data={titles}
                  {...dataState}
                  onDataStateChange={(e: GridDataStateChangeEvent) => {
                    setDataState(e.dataState);
                  }}>
                  <Column field={'name'} title={'Title'} />
                  <Column field={'assignedTo'} title={'Assigned To'} />

                  <GridNoRecords>
                    <div
                      className={
                        'p-4 d-flex justify-content-center flex-column text-muted'
                      }>
                      <span className="material-symbols-outlined">
                        zone_person_alert
                      </span>
                      <small>No data found</small>
                    </div>
                  </GridNoRecords>
                </Grid>
              )}
              {showInventories && (
                <Grid
                  id={'grid-inventories'}
                  pageable={DefaultGridSettings.pagerSettings}
                  sortable={true}
                  data={inventories}
                  {...dataState}
                  onDataStateChange={(e: GridDataStateChangeEvent) => {
                    setDataState(e.dataState);
                  }}>
                  <Column field={'name'} title={'Item'} />
                  <Column field={'quantity'} title={'Qty'} />

                  <GridNoRecords>
                    <div
                      className={
                        'p-4 d-flex justify-content-center flex-column text-muted'
                      }>
                      <span className="material-symbols-outlined">
                        zone_person_alert
                      </span>
                      <small>No data found</small>
                    </div>
                  </GridNoRecords>
                </Grid>
              )}
            </Row>
          </div>
        </Popup>
      )}
    </div>
  );
};
