import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { PlayerContext } from '../../../../../contexts/player';
import ChatWindow from '../../../../../components/chat/chat-window';
import { ShowChat } from '../../../../../utils/game-engine/chat';
import { GameDocumentContext } from '../../../../../contexts/game-document';

export const Chat = () => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [playerState] = useContext(PlayerContext);
  const [showChat, setShowChat] = useState<boolean>(true);

  useEffect(() => {
    const result = ShowChat(
      playerState?.playerState!,
      gameDocument?.gameDocument!
    );
    setShowChat(result!);
  }, [playerState?.playerState?.status]);

  return (
    <>
      <Container className={'drawer__body p-0'}>
        {showChat && (
          <>
            <Row>
              <Col className={'p-4'}>
                <ChatWindow />
              </Col>
            </Row>
          </>
        )}
      </Container>
      <Container className={'drawer__footer p-0 text-center pb-2'} />
    </>
  );
};
