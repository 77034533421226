import merge from 'lodash.merge';
import { GameDocumentState } from '../../contexts/game-document';
import { PlayerContextState } from '../../contexts/player';
import { TeamContextState } from '../../contexts/team';
import {
  AlgorithmControlStructure,
  AlgorithmStep
} from '../../types/algorithm';
import {
  GameState,
  GameStatus,
  PlayerStateFull,
  TeamStateFull
} from '../../types/state';
import { ElapsedTime, PausedStatus } from '../../types/state/game/game-state';
import { PlayerAlgorithmHandler, PlayerAlgorithmHandlerAsync } from './player';
import { TeamAlgorithmHandler } from './team';

export const AlgorithmEventHandlerAsync = async (
  gameDocument: GameDocumentState,
  player: PlayerContextState,
  team: TeamContextState,
  algorithm: AlgorithmStep | AlgorithmControlStructure
): Promise<PlayerStateFull | TeamStateFull> => {
  if (algorithm.identifier === 'player') {
    return await PlayerAlgorithmHandlerAsync(gameDocument, player, algorithm);
  } else {
    return await TeamAlgorithmHandler(gameDocument, team, player, algorithm);
  }
};

export const AlgorithmEventHandlerPlayer = (
  gameDocument: GameDocumentState,
  player: PlayerContextState,
  team: TeamContextState,
  algorithm: AlgorithmStep | AlgorithmControlStructure
) => {
  if (algorithm.identifier === 'player') {
    return PlayerAlgorithmHandler(gameDocument, player, algorithm);
  }
};

/**
 * Start game
 * @param gameState - The gameState
 */
export const StartGameAsync = async (gameState: GameState) => {
  if (!gameState.elapsedTime) {
    gameState.elapsedTime = [];
  }
  const started: string = new Date().toISOString();
  gameState.elapsedTime.push({
    startedTime: started,
    pausedTime: undefined
  });

  return merge(gameState, {
    status: 'starting' as GameStatus,
    startedUtc: started,
    elapsedTime: gameState.elapsedTime
  });
};

/**
 * End game
 * @param gameState - The gameState
 */
export const EndGameAsync = async (gameState: GameState) => {
  const lastIndex: number = (gameState.elapsedTime?.length ?? 0) - 1;
  return merge(gameState, {
    status: 'finished' as GameStatus,
    pausedStatus: undefined as PausedStatus,
    finishedUtc: new Date().toISOString(),
    elapsedTime: gameState.elapsedTime?.map<ElapsedTime>(
      (item: ElapsedTime, index: number) =>
        lastIndex === index
          ? { ...item, pausedTime: new Date().toISOString() }
          : { ...item }
    )
  });
};

/**
 * Pause game
 * @param gameState - The gameState
 * @param currentStatus - The current status before game paused
 */
export const PausedGameAsync = async (
  gameState: GameState,
  currentStatus: GameStatus
) => {
  const lastIndex: number = (gameState.elapsedTime?.length ?? 0) - 1;
  return merge(gameState, {
    status: 'paused' as GameStatus,
    pausedStatus: currentStatus as PausedStatus,
    elapsedTime: gameState.elapsedTime?.map<ElapsedTime>(
      (item: ElapsedTime, index: number) =>
        lastIndex === index
          ? { ...item, pausedTime: new Date().toISOString() }
          : { ...item }
    )
  });
};

/**
 * Resume game
 * @param gameState - The gameState
 */
export const ResumeGameAsync = async (gameState: GameState) => {
  if (!gameState.elapsedTime) {
    gameState.elapsedTime = [];
  }
  const started: string = new Date().toISOString();
  gameState.elapsedTime.push({
    startedTime: started,
    pausedTime: undefined
  });
  return merge(gameState, {
    status: gameState?.pausedStatus as GameStatus,
    pausedStatus: undefined as PausedStatus,
    elapsedTime: gameState.elapsedTime
  });
};

export const executeControlStructureStepEventHandler = async (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  if (stepControlStructure.identifier === 'score') {
    if (stepControlStructure.condition === 'is') {
      if (stepControlStructure.operation === 'greaterOrEqual') {
        return controlStructureScoreIsGreaterOrEqualThanArgumentQuantity(
          stepControlStructure,
          player,
          team
        );
      }

      if (stepControlStructure.operation === 'less') {
        return controlStructureScoreIsLessThanArgumentQuantity(
          stepControlStructure,
          player,
          team
        );
      }
    }

    if (stepControlStructure.condition === 'isNot') {
      if (stepControlStructure.operation === 'greaterOrEqual') {
        return controlStructureScoreIsLessOrEqualThanArgumentQuantity(
          stepControlStructure,
          player,
          team
        );
      }

      if (stepControlStructure.operation === 'less') {
        return controlStructureScoreIsGreaterThanArgumentQuantity(
          stepControlStructure,
          player,
          team
        );
      }
    }
    return;
  }

  if (stepControlStructure.condition === 'does') {
    if (stepControlStructure.operation === 'haveItem') {
      return controlStructurePlayerDoesHaveItem(
        stepControlStructure,
        player,
        team
      );
    } else if (stepControlStructure.operation === 'haveRole') {
      return controlStructurePlayerDoesHaveRole(
        stepControlStructure,
        player,
        team
      );
    } else if (stepControlStructure.operation === 'haveScore') {
      return controlStructurePlayerDoesHaveScore(
        stepControlStructure,
        player,
        team
      );
    }
  } else if (stepControlStructure.condition === 'doesNot') {
    if (stepControlStructure.operation === 'haveItem') {
      return controlStructurePlayerDoesNotHaveItem(
        stepControlStructure,
        player,
        team
      );
    } else if (stepControlStructure.operation === 'haveRole') {
      return controlStructurePlayerDoesNotHaveRole(
        stepControlStructure,
        player,
        team
      );
    } else if (stepControlStructure.operation === 'haveScore') {
      return controlStructurePlayerDoesNotHaveScore(
        stepControlStructure,
        player,
        team
      );
    }
  }
};

/**
 * Check compound statement has valid statement or not
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
export const isValidCompoundStatement = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  if (stepControlStructure.condition === 'does') {
    if (stepControlStructure.operation === 'haveItem') {
      let itemQuantity = checkItemQuantity(stepControlStructure, player, team);

      if (itemQuantity >= stepControlStructure?.argumentQuantity!) {
        return true;
      } else {
        return false;
      }
    } else if (stepControlStructure.operation === 'haveRole') {
      if (checkIsHavingRole(stepControlStructure, player, team)) {
        return true;
      } else {
        return false;
      }
    } else if (stepControlStructure.operation === 'haveScore') {
      let score = checkIsHavingScore(stepControlStructure, player, team);

      if (score >= stepControlStructure?.argumentQuantity!) {
        return true;
      } else {
        return false;
      }
    }
  } else if (stepControlStructure.condition === 'doesNot') {
    if (stepControlStructure.operation === 'haveItem') {
      let itemQuantity = checkItemQuantity(stepControlStructure, player, team);

      if (itemQuantity < stepControlStructure?.argumentQuantity!) {
        return true;
      } else {
        return false;
      }
    } else if (stepControlStructure.operation === 'haveRole') {
      if (!checkIsHavingRole(stepControlStructure, player, team)) {
        return true;
      } else {
        return false;
      }
    } else if (stepControlStructure.operation === 'haveScore') {
      let score = checkIsHavingScore(stepControlStructure, player, team);
      if (score < stepControlStructure?.argumentQuantity!) {
        return true;
      } else {
        return false;
      }
    }
  }
};

/**
 * Check all statement valid or not
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
const isValidStatement = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState,
  parentResult: boolean
) => {
  const compoundStatements = stepControlStructure?.ifCompoundStatements;

  if (!compoundStatements || compoundStatements.length === 0) {
    return parentResult;
  }

  const andStatements = compoundStatements.filter((x) => x.type === 'and');
  const orStatements = compoundStatements.filter((x) => x.type === 'or');

  if (
    andStatements.every((andStatement) =>
      isValidCompoundStatement(
        andStatement as AlgorithmControlStructure,
        player,
        team
      )
    )
  ) {
    if (parentResult) {
      return true;
    }
  }

  if (
    orStatements.some((orStatement) =>
      isValidCompoundStatement(
        orStatement as AlgorithmControlStructure,
        player,
        team
      )
    )
  ) {
    if (parentResult) return true;

    return true;
  }

  return false;
};

/**
 * Get player or team item quantity
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
const checkItemQuantity = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  let itemQuantity =
    stepControlStructure?.identifier === 'player'
      ? (player?.playerState?.inventory?.find(
          (x) => x.id === stepControlStructure?.argumentAssetId
        )?.quantity ?? 0)
      : (team?.teamState?.inventory?.find(
          (x) => x.id === stepControlStructure?.argumentAssetId
        )?.quantity ?? 0);

  return itemQuantity;
};

/**
 * Check player or team have a role or not
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
const checkIsHavingRole = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  return stepControlStructure?.identifier === 'player'
    ? player?.playerState?.titles?.find(
        (x) => x === stepControlStructure?.argumentAssetId
      )
    : team?.teamState?.titles?.find(
        (x) => x === stepControlStructure?.argumentAssetId
      );
};

/**
 * Get player or team score
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
const checkIsHavingScore = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  return stepControlStructure?.identifier === 'player'
    ? (player?.playerState?.score ?? 0)
    : (team?.teamState?.score ?? 0);
};

/**
 * Get player or team score
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
const getTotalScoreOnOpenedTask = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  let totalScore: number = 0;

  const openedTaskIds = player.playerState?.openedTask;

  openedTaskIds?.forEach((openedTaskId) => {
    const openedTask = player.playerState?.tasks?.find(
      (item) => item.id === openedTaskId
    );

    openedTask?.taskContentFormAnswers?.forEach((item) => {
      totalScore += item.score!;
    });
  });

  return totalScore;
};

/**
 * Check player or team have an item
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
const controlStructurePlayerDoesHaveItem = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  let itemQuantity = checkItemQuantity(stepControlStructure, player, team);

  let parentResult = itemQuantity >= stepControlStructure?.argumentQuantity!;

  let isValidCompoundStatement = isValidStatement(
    stepControlStructure,
    player,
    team,
    parentResult
  );

  if (isValidCompoundStatement) {
    return controlStructureIFStep(stepControlStructure);
  } else {
    return controlStructureELSEStep(stepControlStructure);
  }
};

/**
 * Check player or team have no item
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
const controlStructurePlayerDoesNotHaveItem = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  let itemQuantity = checkItemQuantity(stepControlStructure, player, team);

  let parentResult = itemQuantity < stepControlStructure?.argumentQuantity!;

  let isValidCompoundStatement = isValidStatement(
    stepControlStructure,
    player,
    team,
    parentResult
  );

  if (isValidCompoundStatement) {
    return controlStructureIFStep(stepControlStructure);
  } else {
    return controlStructureELSEStep(stepControlStructure);
  }
};

/**
 * Check player or team have a role
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
const controlStructurePlayerDoesHaveRole = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  let parentResult = checkIsHavingRole(stepControlStructure, player, team)
    ? true
    : false;
  let isValidCompoundStatement = isValidStatement(
    stepControlStructure,
    player,
    team,
    parentResult
  );
  if (isValidCompoundStatement) {
    return controlStructureIFStep(stepControlStructure);
  } else {
    return controlStructureELSEStep(stepControlStructure);
  }
};

/**
 * Check player or team have no role
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
const controlStructurePlayerDoesNotHaveRole = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  let parentResult = !checkIsHavingRole(stepControlStructure, player, team)
    ? true
    : false;

  let isValidCompoundStatement = isValidStatement(
    stepControlStructure,
    player,
    team,
    parentResult
  );

  if (isValidCompoundStatement) {
    //Add validation compount statement
    return controlStructureIFStep(stepControlStructure);
  } else {
    return controlStructureELSEStep(stepControlStructure);
  }
};

/**
 * Check player or team have a score
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
const controlStructurePlayerDoesHaveScore = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  let score = checkIsHavingScore(stepControlStructure, player, team);

  let parentResult = score >= stepControlStructure?.argumentQuantity!;

  let isValidCompoundStatement = isValidStatement(
    stepControlStructure,
    player,
    team,
    parentResult
  );

  if (isValidCompoundStatement) {
    return controlStructureIFStep(stepControlStructure);
  } else {
    return controlStructureELSEStep(stepControlStructure);
  }
};

/**
 * Check player or team have no score
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
const controlStructurePlayerDoesNotHaveScore = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  let score = checkIsHavingScore(stepControlStructure, player, team);

  let parentResult = score < stepControlStructure?.argumentQuantity!;

  let isValidCompoundStatement = isValidStatement(
    stepControlStructure,
    player,
    team,
    parentResult
  );

  if (isValidCompoundStatement) {
    return controlStructureIFStep(stepControlStructure);
  } else {
    return controlStructureELSEStep(stepControlStructure);
  }
};

/**
 * Check task score is greater or equal than argument quantity
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
const controlStructureScoreIsGreaterOrEqualThanArgumentQuantity = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  let score = getTotalScoreOnOpenedTask(stepControlStructure, player, team);

  let parentResult = score >= stepControlStructure?.argumentQuantity!;

  let isValidCompoundStatement = isValidStatement(
    stepControlStructure,
    player,
    team,
    parentResult
  );

  if (isValidCompoundStatement) {
    return controlStructureIFStep(stepControlStructure);
  } else {
    return controlStructureELSEStep(stepControlStructure);
  }
};

/**
 * Check task score is greater or equal than argument quantity
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
const controlStructureScoreIsLessOrEqualThanArgumentQuantity = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  let score = getTotalScoreOnOpenedTask(stepControlStructure, player, team);

  let parentResult = score <= stepControlStructure?.argumentQuantity!;

  let isValidCompoundStatement = isValidStatement(
    stepControlStructure,
    player,
    team,
    parentResult
  );

  if (isValidCompoundStatement) {
    return controlStructureIFStep(stepControlStructure);
  } else {
    return controlStructureELSEStep(stepControlStructure);
  }
};

/**
 * Check task score is less than argument quantity
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
const controlStructureScoreIsLessThanArgumentQuantity = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  let score = getTotalScoreOnOpenedTask(stepControlStructure, player, team);

  let parentResult = score < stepControlStructure?.argumentQuantity!;

  let isValidCompoundStatement = isValidStatement(
    stepControlStructure,
    player,
    team,
    parentResult
  );

  if (isValidCompoundStatement) {
    return controlStructureIFStep(stepControlStructure);
  } else {
    return controlStructureELSEStep(stepControlStructure);
  }
};

/**
 * Check task score is less than argument quantity
 * @param stepControlStructure
 * @param player
 * @param team
 * @returns
 */
const controlStructureScoreIsGreaterThanArgumentQuantity = (
  stepControlStructure: AlgorithmControlStructure,
  player: PlayerContextState,
  team: TeamContextState
) => {
  let score = getTotalScoreOnOpenedTask(stepControlStructure, player, team);

  let parentResult = score > stepControlStructure?.argumentQuantity!;

  let isValidCompoundStatement = isValidStatement(
    stepControlStructure,
    player,
    team,
    parentResult
  );

  if (isValidCompoundStatement) {
    return controlStructureIFStep(stepControlStructure);
  } else {
    return controlStructureELSEStep(stepControlStructure);
  }
};

const controlStructureIFStep = (
  stepControlStructure: AlgorithmControlStructure
) => {
  return stepControlStructure?.ifSteps ?? [];
};

const controlStructureELSEStep = (
  stepControlStructure: AlgorithmControlStructure
) => {
  return stepControlStructure?.elseSteps ?? [];
};

/**
 * Get steps of the event action onTaskCompleted base on location (map, zone)
 * @param location - world-map | zoneId
 * @param gameDocument - game document
 * @return steps - AlogithmSteps[] | AlgorithmControlStructure[]
 */

export const getStepTaskCompletedMap = (
  location: string,
  gameDocument: GameDocumentState
): AlgorithmStep[] | AlgorithmControlStructure[] => {
  if (location === 'world-map') {
    const worldMap = gameDocument?.gameDocument?.rules?.worldMap;
    if (worldMap) {
      const { events: { onTaskCompletedMap: { steps = [] } = {} } = {} } =
        worldMap;

      return steps || [];
    }
  } else {
    const zone = gameDocument?.gameDocument?.assets?.zones?.find(
      (x) => x.id === location
    );
    if (zone) {
      const { events: { onTaskCompletedMap: { steps = [] } = {} } = {} } = zone;
      return steps || [];
    }
  }
  return [];
};
